import { React } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import figures3 from "../../../Assets/SVGs/figures3.svg";
import taskilledRobot from "../../../Assets/Images/taskilledRobot.png"

export const SecondPart = () => {
  return (
    <SecondPartStyle>
      <div className="bg">
        <div className="robots"></div>
        <div className="text">
          <h1>Startup Building Lab</h1>
          <p>
            A better way for testing business cases and building new skills is using simulation games. Provided task-based and experience-oriented programs are more enjoyable and beneficial.
          </p>{" "}
          <Link to="/all-games">Start</Link>
        </div>
        <div className="figures3"></div>
      </div>
    </SecondPartStyle>
  );
};

const SecondPartStyle = styled.div`
  padding-bottom: 5rem;
  z-index: -2;
  .bg {
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .figures3 {
      background: url(${figures3});
      background-repeat: no-repeat;
      background-size: auto;
      background-position: top;
      position: absolute;
      height: 270px;
      width: 270px;
      z-index: 1;
      align-self: center;
      left: 82%;
      transform: scale(1.6) rotate(-90deg);
    }
    .robots {
      background: url(${taskilledRobot});
      background-repeat: no-repeat;
      background-size: contain;
      transform: scale(1.35) translateX(5rem) translateY(-2rem);
    }
    .text {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-top: 2rem;
      z-index: 3;
      h1 {
        color: #4f4f4f;
        font-size: clamp(1.6rem, 5vw, 2.2rem);
      }
      p {
        color: #828282;
        margin-top: 1rem;
        line-height: 1.5rem;
        font-weight: 100;
        font-size: clamp(1.125rem, 2.5vw, 1.2rem);
      }
      a {
        font-family: "Euclid";
        text-decoration: none;
        width: fit-content;
        border: none;
        color: #fff;
        background-color: var(--purple);
        border-radius: 0.5rem;
        padding: 1rem 4.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        transition: all 0.1s;
        font-size: clamp(1.125rem, 2.5vw, 1.2rem);
        &:hover {
          background-color: var(--purple-shadow);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem 0 3.5rem 0;
    z-index: -2;
    background-color: #f8f8f8;
    .bg {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .figures3 {
        display: none;
      }
      .robots {
        /* border: 1px solid red; */
        width: 270px;
        height: 270px;
        background: url(${taskilledRobot});
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1.35) translateX(0rem) translateY(6rem);
      }
      .text {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 90%;
        margin-top: 2rem;
        h1 {
          color: #4f4f4f;
          transform: translateY(-19rem);
        }
        p {
          color: #828282;
          margin-top: 8rem;
          line-height: 1.5rem;
          font-weight: 100;
        }
        a{
          font-family: "Euclid";
          width: fit-content;
          border: none;
          color: #fff;
          background-color: var(--purple-shadow);
          border-radius: 0.5rem;
          padding: 1rem 3.5rem;
          margin: 0 auto;
          margin-top: 1.5rem;
          cursor: pointer;
          transition: all 0.1s;
          :hover {
            background-color: var(--purple-shadow);
          }
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    .bg {
      .robots {
        width: 250px;
      }
      .text {
        p {
          margin-top: 5rem;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .bg {
      .robots {
        width: 200px;
      }
      .text {
        p {
          margin-top: 2rem;
        }
      }
    }
  }
`;