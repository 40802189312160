import styled from "styled-components";
import { AboutProgram } from "./AboutProgram";
import { ProgramSpecs } from "./ProgramSpecs";
import { CurrentLocation } from "../CurrentLocation";
import { Payment } from "../Payment/_Payment";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Button, Text } from "@mantine/core";
import EntranceForHackathon from "../../Components/EntranceForHackathon/EntranceForHackathon";
import { DoorEnter } from "tabler-icons-react";

function EnrollmentCheck({ id }){

  const { data:userEnrolled } = useQuery(['user enrolled'],
    async () => {
      return await axios.get(`/api/successfuly_paid_course/${id}/`).then(res => res.data)
    }
  )

  if(userEnrolled?.successfuly_paid === false){
    return(
      <Text color={"red"} align="center" size={20} mt={30} mb={10}>You haven't enrolled in this course</Text>
    )
  }

  if(userEnrolled?.successfuly_paid === true){
    return(
      <Button fullWidth size="md" color={"gray.8"} component={Link} to={`/course/${id}`}>Enter</Button>
    )
  }
}

export function ProgramInfo({ props }){

  const { entryData, user, type } = props;

  const location = useLocation()

  return(
    <ProgramInfoStyle>

      <CurrentLocation props={{ entryData, type }} />

      <div className="wrapper">
        <AboutProgram props={{ entryData }} />
        <div className="right">
          <ProgramSpecs props={{ entryData }} />
          {
            (location?.pathname.includes('hackathon') && entryData.is_pay === true) ?
            
            <Button component={Link} to={`/hackathon/${entryData?.id}`} style={{ width:"100%" }} size="md" radius={"md"} fullwidth color="gray.8" leftIcon={<DoorEnter/>}>Enter</Button>
            :
            location?.pathname.includes('bootcamp') ?
              <EnrollmentCheck id={entryData?.id}/>
            : location?.pathname.includes('hackathon') ?
              <>
                <Text weight={500} size={18} align="center" mb={0}>You must be in a team to participate</Text>
                <Text size={15} align="center" mb={15}>Create a team and add team members or join to an existing team</Text>
                <EntranceForHackathon/>
              </>
            :
              <Payment props={{ user, entryData, type }} />
          }
          {
            
          }
        </div>
      </div>

    </ProgramInfoStyle>
  )
}

const ProgramInfoStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem auto 5rem auto;
  .wrapper{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 1.5rem;
    margin: .5rem 0;
  }
  @media screen and (max-width:1024px){
    width: 90%;
    margin: 0 auto;
    padding: 0rem;
    .wrapper{
      grid-template-columns: 1fr;
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
  }
`