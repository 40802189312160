import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AnimateOpa } from "./AnimateOpa";
import { ShowSidebar } from "./Layout/LayoutHelper";
import axios from "axios";
import {
  Button,
  FileInput,
  Group,
  Image,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import { Plus, Upload } from "tabler-icons-react";
import { useContext, useState } from "react";
import { AppContext } from "../Helpers/Context";
import { Link } from "react-router-dom";
import { MobileMenuNew } from "./Layout/Navbar/Menu";

function CreateTeamModal({ props }) {
  const { user } = useContext(AppContext);

  const { createTeamModalOpen, setCreateTeamModalOpen } = props;

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    [`create team`],
    async (e) => {
      e.preventDefault();
      return await axios.post("/hackathon/team_create/", {
        name: e.target.name.value,
        user: user.id,
        hackathon: 3,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["teams"]);
        setCreateTeamModalOpen(false);
      },
    }
  );

  return (
    <Modal
      overlayBlur={5}
      radius={"md"}
      title={"Create a team"}
      opened={createTeamModalOpen}
      onClose={() => setCreateTeamModalOpen(false)}
    >
      <form onSubmit={(e) => mutate(e)}>
        <Image radius={"md"} height={220} src="" withPlaceholder />
        <FileInput
          name="image"
          radius={"md"}
          mt={15}
          placeholder="Image"
          label="Upload an image"
          rightSection={<Upload size={20} />}
          rightSectionWidth={45}
        />
        <TextInput
          name="name"
          radius={"md"}
          mt={15}
          placeholder="Team name"
          label="Team name"
          required
        />
        <Button
          loading={isLoading}
          type="submit"
          radius={"md"}
          mt={15}
          fullWidth
          color="gray.8"
          leftIcon={<Plus />}
        >
          Create team
        </Button>
      </form>
    </Modal>
  );
}

const AllTeams = () => {
  const { user } = useContext(AppContext);

  const { data } = useQuery(
    ["teams"],
    async () => await axios.get("/hackathon/teams/").then((res) => res.data)
  );

  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);

  return (
    <ShowSidebar>
      <CreateTeamModal
        props={{ createTeamModalOpen, setCreateTeamModalOpen }}
      />
      <AnimateOpa>
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "0.75rem",
            padding: "2rem",
            border: "1px solid #DEE2E6",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text size={22}>All Teams</Text>
            {/* <Button radius={"md"} onClick={() => setCreateTeamModalOpen(true)} color="gray.8" leftIcon={<Plus/>}>Create team</Button> */}
          </div>
          <div style={{ display: "flex", gap: ".5rem", marginTop: "1rem" }}>
            {data?.length > 0 &&
              data?.map((e) => (
                <Link
                  to={`/teams/${e?.id}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                    textDecoration: "none",
                    fontWeight: "400",
                    border: "1px solid #eee",
                    width: "fit-content",
                    borderRadius: ".5rem",
                    padding: ".5rem 1rem",
                  }}
                >
                  <Group>
                    <Image
                      radius={100}
                      width={25}
                      height={25}
                      src={e?.image}
                      withPlaceholder
                    />
                    <Text size={20}>{e?.name}</Text>
                  </Group>
                </Link>
              ))}
          </div>
        </div>
      </AnimateOpa>
    </ShowSidebar>
  );
};

export default AllTeams;
