import { React } from "react";
import styled from "styled-components";
import {  } from "../../../Components/Layout/LayoutHelper";
import { FirstPart } from "./FirstPart";
import { SecondPart } from "./SecondPart";
import { ThirdPart } from "./ThirdPart";
import { ForthPart } from "./ForthPart";
import { Newsletter } from "./Newsletter";
import { Partners } from "../../../Components/Partners";

const Landing = () => {
  return (
    <>

      <LandingStyle>
        <div className="wrapper">
          <FirstPart />
          <SecondPart />
          <ThirdPart />
          <ForthPart />
          <Newsletter />
          <Partners props={{ showHeader:true }}/>
        </div>
      </LandingStyle>

    </>
  );
};

export default Landing;

const LandingStyle = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      width: 100%;
    }
  }
`;