import axios from "axios";
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Spoiler, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ProgramCard } from "../../../Components/ProgramCard";

const _SchoolInfo = () => {

  const { id } = useParams();

  let type = "course"

  const { data:schoolInfo } = useQuery(['school info'],
    async () => {
      return axios.get(`/school/data/${id}/`).then(res => res?.data)
    }
  )

  const { data:programs, } = useQuery(['school programs'],
    async () => {
      return axios.get(`/school/course_list/${id}/`).then(res => res?.data)
    }
  )

  // const { data:students, } = useQuery(['school students'],
  //   async () => {
  //     return axios.get(`/school/school_user_list/${id}/`).then(res => res?.data)
  //   }
  // )

  // students?.results?.forEach(e => {
  //   axios.get(`/school/users_pay_toschool/?school_id=8&course_id=16&user_id=${e.id}`)
  // })

  if(schoolInfo){
    return (
      <div>
        <SchoolStyle>
          <div className="header">
            <img src={schoolInfo?.School?.[0]?.profile_img} alt="" />
            <div className="title">
              <h1>{schoolInfo?.School?.[0]?.title}</h1>
              <Text size={22}>Data Science üzrə beynəlxalq təcrübə və karyera proqramı</Text>
            </div>
          </div>
          <div className="inside">
            {
              programs?.length > 0 && programs[0]?.pay === false &&
              <Text size={18} align="center" color={"red"}>Enrollment for this bootcamp has been expired</Text>
            }
            <h2>About</h2>
            <Spoiler maxHeight={95} showLabel="Show more" hideLabel="Hide">
              {
                schoolInfo?.School?.[0]?.description && parse(schoolInfo?.School?.[0]?.description)
              }
            </Spoiler>
            <h2>Programs</h2>
            <div className="listX">
              {
                programs?.length > 0 && programs?.map((content) =>
                  <div className="cardResized">
                    <ProgramCard key={content.id} props={{ link:type === "course" ? `/bootcamp/courses/${content.id}` : `/bootcamp/internships/${content.id}`, image:content.image, title:content.title }} />
                  </div>
                )
              }
            </div>
          </div>
        </SchoolStyle>
      </div>
    )
  }
}

export default _SchoolInfo

const SchoolStyle = styled.div`
  h1,h2,h3{
    font-weight: 500;
  }
  padding-bottom: 10rem;
  background-color: #F8F9FA;
  .header{
    position: relative;
    img{
      width: 100%;
      height: 300px;
      object-fit: cover;
      filter: brightness(0.66);
    }
    .title{

      position: absolute;
      left: 0;
      top: 30%;
      right: 0;
      text-align: center;
      color: #fff;
    }
    h1{
      font-size: 40px;
      margin-bottom: 1rem;
    }
    .edit{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      button{
        background-color: #eeeeee55;
        color: #fff;
        &:hover{
          background-color: #fff;
          color: black;
        }
      }
      .delete{
        background-color: #ff000055;
        &:hover{
          background-color: #fff;
          color: red;
        }
      }
    }
  }
  .inside{
    width: 90%;
    margin: 3rem auto;
    .listX{
      width: 100%;
      margin: 0 auto;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .cardResized{
        display: flex;
        width: 260px;
      }
    }
  }
`