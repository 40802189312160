import {
  Button,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Loader,
  Menu,
  Modal,
  Spoiler,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
// import { AnimatePresence, motion } from "framer-motion"
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useContext, useState } from "react";
import {
  Clock,
  DotsVertical,
  MessageDots,
  MessageShare,
  ThumbUp,
  Trash,
  X,
} from "tabler-icons-react";
import { AppContext } from "../../Helpers/Context";
import { CommentSection } from "../CommentSection";
import { ProgramCard } from "../ProgramCard";
import { useMediaQuery } from "@mantine/hooks";

export function Post({ props }) {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");

  const xs4 = useMediaQuery("(max-width: 450px)");
  const { user } = useContext(AppContext);

  const { post } = props;

  const queryClient = useQueryClient();

  let [postReview, setPostReview] = useState({
    current: post?.user_like,
    count: post?.likes_c,
  });
  let [commentFieldOpen, setCommentFieldOpen] = useState(false);

  const { mutate: deletePost, isLoading } = useMutation(
    ["delete post"],
    async () => await axios.delete(`/blog/${post?.id}/`),
    { onSuccess: () => queryClient.invalidateQueries([`feed`]) }
  );

  const { mutate: ratePost } = useMutation(
    ["rate post"],
    async () => {
      if (postReview.current === false) {
        setPostReview((prev) => ({ current: true, count: prev.count + 1 }));
      } else {
        setPostReview((prev) => ({ current: false, count: prev.count - 1 }));
      }
      return await axios.patch(
        `/blog/${post?.id}/?${
          post?.user_like === true ? "dislikes" : "likes"
        }=${user?.id}`
      );
    }
    // { onSuccess: () => queryClient.invalidateQueries("feed") }
  );

  const [imageFull, setImageFull] = useState(false);

  return (
    // <AnimatePresence mode="wait">
    <div
      style={{
        border: "1px solid #DEE2E6",
        backgroundColor: "#fff",
        padding: ".5rem",
        borderRadius: "0.75rem",
      }}
    >
      <Group
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: "0.5rem",
          padding: ".5rem",
        }}
      >
        <Group>
          <Image
            radius={100}
            src={post?.user?.profile_img}
            width={45}
            height={45}
            withPlaceholder
          />
          <div>
            <Text size={15}>
              {post?.user?.first_name}{" "}
              {post?.user?.last_name !== "." && post?.user?.last_name}
            </Text>
            <Group style={{ gap: "0.25rem" }}>
              <Clock color="gray" size={14} />
              <Text size={14} color="dimmed">
                {timeAgo.format(new Date(post?.created_at))}
              </Text>
            </Group>
          </div>
        </Group>

        {post?.user?.id === user?.id && (
          <div>
            <Menu trigger="hover">
              <Menu.Target>
                <Button px={12} variant="subtle" color={"gray.8"}>
                  <DotsVertical color="#495057" />
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  color={"red"}
                  icon={
                    isLoading ? <Loader size={"sm"} color="red" /> : <Trash />
                  }
                  onClick={() => deletePost()}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        )}
      </Group>

      <Container my={20} px={10}>
        {post?.video && post?.video.includes("youtube.com/watch?") ? (
          <iframe
            src={`https://www.youtube.com/embed/${
              post.video.split("/watch?v=")[1].split("&t=")[0]
            }`}
            allow="autoplay; encrypted-media"
            allowFullScreen
            width="100%"
            height="320"
            title="video"
            style={{ border: "0", borderRadius: ".25rem" }}
          />
        ) : (
          // :
          // post?.video && post?.video.includes("youtube.com/shorts") ?
          // <iframe src={`https://www.youtube.com/embed/${post?.video}`}
          //   allow='autoplay; encrypted-media'
          //   allowFullScreen
          //   width="100%" height="320"
          //   title='video'
          //   style={{ border:"0", borderRadius:".25rem" }}
          // />
          post?.video && <a href={post?.video}>{post?.video}</a>
        )}

        {post?.description !== "." && (
          <Spoiler
            mt={-10}
            mb={10}
            maxHeight={120}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{ control: { color: "#868E96" } }}
          >
            <Text size={15}>{post?.description}</Text>
          </Spoiler>
        )}

        {post?.image && (
          <Center
            mt={5}
            style={{ cursor: "pointer" }}
            onClick={() => setImageFull(true)}
          >
            <Image
              fit="contain"
              height={"100%"}
              src={post?.image}
              styles={{ image: { maxHeight: "500px" } }}
            />
          </Center>
        )}

        <Modal
          size="auto"
          opened={imageFull}
          onClose={() => setImageFull(false)}
          withCloseButton={false}
          bg={"0"}
          overlayBlur={"2"}
          overlayColor="#495057"
          shadow="0"
          transitionDuration={200}
          styles={{ modal: { backgroundColor: "#00000000" } }}
        >
          <div
            style={{
              display: "flex",
              borderRadius: "0 1rem 1rem 0",
              backgroundColor: "#f1f3f5",
              alignItems: "start",
              gap: "1rem",
            }}
          >
            <Image
              fit="contain"
              height={"100%"}
              src={post?.image}
              styles={{ image: {} }}
            />
            <div style={{ margin: "1rem 1rem 1rem 0", minWidth: "450px" }}>
              <Group
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    padding: ".25rem .5rem",
                  }}
                >
                  <Image
                    radius={100}
                    src={post?.user?.profile_img}
                    width={45}
                    height={45}
                    withPlaceholder
                  />
                  <div>
                    <Text size={15}>
                      {post?.user?.first_name}{" "}
                      {post?.user?.last_name !== "." && post?.user?.last_name}
                    </Text>
                    <Group style={{ gap: "0.25rem" }}>
                      <Clock color="gray" size={14} />
                      <Text size={14} color="dimmed">
                        {timeAgo.format(new Date(post?.created_at))}
                      </Text>
                    </Group>
                  </div>
                </div>
                {post?.user?.id === user?.id && (
                  <div>
                    <Menu trigger="hover" position="left" radius={"lg"}>
                      <Menu.Target>
                        <Button px={12} variant="subtle" color={"gray.8"}>
                          <DotsVertical color="#495057" />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          color={"red"}
                          icon={
                            isLoading ? (
                              <Loader size={"sm"} color="red" />
                            ) : (
                              <Trash />
                            )
                          }
                          onClick={() => deletePost()}
                        >
                          Delete
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                )}
              </Group>
              {post?.description !== "." && (
                <Spoiler
                  mx=".5rem"
                  my={"md"}
                  maxHeight={120}
                  showLabel="Show more"
                  hideLabel="Hide"
                  styles={{ control: { color: "#868E96" } }}
                >
                  <Text size={15}>{post?.description}</Text>
                </Spoiler>
              )}
              <Divider mt={"sm"} mb={"lg"} />
              <CommentSection props={{ post }} />
            </div>
          </div>
        </Modal>
      </Container>

      {(post?.course || post?.internship) && (
        <Center mb={10}>
          <ProgramCard
            props={{
              link: `${post?.course ? "/courses/" : "/internships/"}${
                post?.course ? post?.course?.id : post?.internship?.id
              }`,
              image: post?.course?.image || post?.internship?.image,
              title: post?.course?.title || post?.internship?.title,
            }}
          />
        </Center>
      )}

      <Group ml={10} mb={5}>
        <Text>{postReview.count} people liked this</Text>
      </Group>

      <Group
        style={{
          padding: "0 .25rem",
          gap: 0,
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Group
          p={".25rem"}
          style={{
            gap: "0.25rem",
            width: "100%",
            display: "grid",
            gridTemplateColumns: `${xs4?"1fr 1fr":"1fr 1fr 1fr"}`,
          }}
        >
          <Button
            onClick={() => ratePost()}
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant={"default"}
            leftIcon={
              <ThumbUp
                color={"black"}
                fill={postReview.current === true ? "gray" : "white"}
              />
            }
          >
            Like
          </Button>
          <Button
            onClick={() => setCommentFieldOpen((prev) => !prev)}
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant="default"
            leftIcon={<MessageDots />}
          >
            Comment
          </Button>
          <Button
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant="default"
            leftIcon={<MessageShare />}
          >
            Share
          </Button>
        </Group>
      </Group>

      {
        commentFieldOpen && (
          // <motion.div
          //   initial={{ y:-50, opacity:0 }}
          //   animate={{ y:0, opacity:1 }}
          //   exit={{ y:-50, opacity:0 }}
          //   key={post.id + "comments"}
          // >
          <CommentSection props={{ post }} />
        )
        // </motion.div>
      }
    </div>
    // </AnimatePresence>
  );
}
