import { useContext } from "react"
import styled from "styled-components"
import { Navbar } from "./Navbar/_Navbar"
import { Sidebar } from "./Sidebar"
import { Footer } from "./Footer"
import { AppContext } from "../../Helpers/Context"
import { useLocation } from "react-router-dom"
import { isTaskool } from "../../Helpers/RouterLogic"

export function ShowNavbar() {

  const { user } = useContext(AppContext);

  const location = useLocation()
  const path = location.pathname

  let nox = ["upload", "create", "edit", "login", "register"]
  if(nox.some(x => path.includes(x))) return null

  if(location.pathname.includes("speciality")){
    return null
  }

  return user?.id ? <Navbar isTaskool={isTaskool}/> : <Navbar register isTaskool={isTaskool}/>

}

export function ShowFooter() {

  const location = useLocation()
  const path = location.pathname

  let nox = ["upload", "create", "edit", "login", "register"]
  if(nox.some(x => path.includes(x))) return null

  if(location.pathname.includes("speciality")){
    return null
  }

  return <Footer/>
}

export function ShowSidebar(props) {

  const { user } = useContext(AppContext);

  if(user !== undefined) {
    return(
      <WithSidebarStyle>
        <div className="wrapperLayout">
          <div className="sidebarContaine">
            <Sidebar props={{ showProfile:true }}/>
          </div>
          {props.children}
        </div>
      </WithSidebarStyle>
    )
  } else {
    return <>{props.children}</>
  }

}

const WithSidebarStyle = styled.div`
  background-color: #F1F3F5;
  display: flex;
  min-height: 708px;
  .wrapperLayout {
    display: grid;
    grid-template-columns: 1fr 3.2fr;
    grid-gap: 1.5rem;
    margin: 1.5rem auto 1.5rem auto;
    width: 90%;
  }
  .communities{
    
  }
  @media screen and (max-width: 1024px) {
    .wrapperLayout{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 2rem auto 2rem auto;
      .sidebarContaine+div{
        order:1 !important;
      }
      .sidebarContaine {
        order: 2;
        margin-top: 0rem;
      }
    }
  }
  @media screen and (max-width: 568px) {
    .addPostButtons{
      grid-template-columns:1fr 1fr 1fr !important;
    }
    .dashInside{
      display:flex;
      justify-content:center;
      .wrapperHome{
        width:460px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .dashInside{
      .wrapperHome{
        width:400px;
      }
    }
  }
  @media screen and (max-width: 430px) {
    .addPostButtons{
      // display:flex !important;
      // flex-wrap:wrap !important;
      grid-template-columns:1fr 1fr  !important;
    }
    .datamining{
      grid-column-start: 1;
      grid-column-end: 3;
  grid-row-start: 3;
  grid-template-columns: subgrid;
    }
    .sc-dtBdUo.fDZQJB{
      padding:0;
    }
    .dashInside{
      padding:0;
      .wrapperHome{
        width:320px;
      }
    }
  }
`;