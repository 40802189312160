import React from "react";
import { Card, Image, Text, Avatar, Rating, Stack, Group } from "@mantine/core";
import styled from "styled-components";

export const ProjectCard = () => {
  return (
    <Card
      shadow="sm"
      padding="xl"
      component="a"
      href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      target="_blank"
    >
      <Card.Section>
        <Image
          src="https://images.unsplash.com/photo-1579227114347-15d08fc37cae?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
          height={160}
          alt="No way!"
        />
      </Card.Section>

      <Text weight={500} size="lg" mt="md">
        Project name
      </Text>

      <Text mt="-4px" color="dimmed" size="sm">
        Team name
      </Text>
      <Avatar.Group mt={16} spacing="sm">
        <Avatar
          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
          alt="avatar"
          radius="xl"
        />
        <Avatar
          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
          alt="avatar"
          radius="xl"
        />
        <Avatar
          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
          alt="avatar"
          radius="xl"
        />
        <Avatar
          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
          alt="avatar"
          radius="xl"
        />
      </Avatar.Group>
      <RatingSystem>
        <div className="rating">
          <div className="avgRate">
            <Rating value={3.5} fractions={2} readOnly />
            <p>4.0</p>
          </div>
          <div className="system">
            <Group className="group">
              <p>Creativity</p>
              <Rating fractions={2} defaultValue={1.5} />
            </Group>
            <Group className="group">
              <p>Innovative</p>
              <Rating fractions={2} defaultValue={4.0} />
            </Group>
            <Group className="group">
              <p>Presentation</p>
              <Rating fractions={2} defaultValue={3.5} />
            </Group>
          </div>
        </div>
        <button>Vote</button>
      </RatingSystem>
    </Card>
  );
};

const RatingSystem = styled.div`
  .rating {
    margin-top: 10px;

    .avgRate {
      padding-left: 5px;
      display: flex;
      align-items: center;

      p {
        color: #545454;
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }

  .system {
    border-radius: 8px;
    background: #fff7e0;
    padding: 5px;
    margin-top: 6px;

    .group {
      display: flex;
      justify-content: space-between;
      p {
        color: #1a1d1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  button {
    width: 100%;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #484848;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 6px;
  }
`;
