import { createStyles, Card, Avatar, Text, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { Users } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white,
    height: "280px",
    position: "relative",
  },

  avatar: {
    border: `2px solid ${theme.white}`,
  },
}));

export function UserCardImage({
  name,
  description,
  image,
  coverImage,
  membersCount,
  link,
  component,
}) {
  const { classes, theme } = useStyles();

  return (
    <Card withBorder padding="xs" radius="md" className={classes.card}>
      <Card.Section
        sx={{
          backgroundImage: `url(${coverImage})`,
          height: 80,
          objectFit: "contain",
          backgroundSize: "100% 100%",
        }}
      />
      <Link to={link}>
        <Avatar
          src={image}
          size={54}
          radius={80}
          mx="auto"
          mt={-30}
          className={classes.avatar}
        />
      </Link>
      <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>
        <Text ta="left" fz="sm" fw={500} mt="sm">
          {name.length < 17 ? name : name.slice(0, 17) + "..."}
        </Text>
      </Link>
      <Text ta="left" fz="sm" c="dimmed">
        <Users size={12} style={{ marginRight: "4px" }} />
        {membersCount} members
      </Text>
      <Text fz="sm" style={{ textAlign: "left" }}>
        {description.length < 34
          ? description
          : description.slice(0, 30) + "..."}
      </Text>
      <Button
        radius="md"
        mt="xl"
        size="sm"
        to={link}
        component={component}
        style={{
          backgroundColor: "#F2C94C",
          width: "90%",
          color: "#000",
          fontWeight: 400,
          fontSize: "14px",
          position: "absolute",
          bottom: "7px",
          right: "9px",
        }}
      >
        View
      </Button>
    </Card>
  );
}
