import { Avatar, Button, Divider, Group, Text, TextInput, UnstyledButton } from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Send } from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";

export function CommentSection({ props }){

  const { post } = props
  const { user } = useContext(AppContext)

  let [ commentField, setCommentField ] = useState({ open:false, comment:"" })

  const { data:comments } = useQuery(['get comments'],
    async () => axios.get(`/blog/comments/?post_id=${post?.id}`).then(res => res.data)
  )

  const { mutate:postComment } = useMutation(['post comment'],
    async (e) =>
    {
      e.preventDefault()
      return await axios.post('/blog/comment/create/',
        {
          post: post?.id,
          user: user?.id,
          comment: commentField?.comment
        }
      )
    },
    {
      onSuccess: () => setCommentField(prev => ({ ...prev, comment:"" }))
    }
  )

  return(
    <div>
      <form onSubmit={(e) => postComment(e)} style={{ display:"flex", alignItems:"center", gap:".5rem", margin:".5rem" }}>
        <Avatar
          src={user?.profile_img}
          size={36}
          radius={100}
        />
        <TextInput
          radius={100}
          placeholder="Add a comment.."
          styles={{ root:{ width:"100%" } }}
          value={commentField?.comment}
          onChange={(e) => setCommentField(prev => ({ ...prev, comment:e.target.value }))}
        />
        <Button type="submit" disabled={commentField?.comment?.length < 1} style={{ fontWeight:"500", color:"black" }} radius={100} color={"ts"} variant="filled" leftIcon={<Send size={20}/>}>Send</Button>
      </form>
      {/* <div style={{ margin:"1rem .5rem .5rem .5rem" }}>
        {
          comments?.results?.map((e, index) => {
            return(
              <>
                {
                  index !== 0 &&
                  <Divider my={8} color={"gray.2"}/>
                }
                <div style={{ display:"flex", gap:"1rem" }}>
                  <Avatar mt={5} src={e?.user?.profile_img} size={36} radius={100}/>
                  <div>
                    <UnstyledButton component={Link} to={`/profile/${e?.user?.id}`}>
                      <Text>{e?.user?.first_name} {e?.user?.last_name}</Text>
                    </UnstyledButton>
                    <Text>{e?.comment}</Text>
                  </div>
                </div>
              </>
            )
          })
        }
      </div> */}
    </div>
  )
}