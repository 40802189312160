import React, { useState, useContext, useRef, useEffect } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import axios from "axios";
import { ArrowNarrowLeft } from "tabler-icons-react";
import {
  Container,
  Image,
  Grid,
  Avatar,
  Card,
  Text,
  Tabs,
  Modal,
  TextInput,
  Button,
  AspectRatio,
  Overlay,
  Stepper,
  Group,
} from "@mantine/core";
import { ProjectCard } from "../../Components/HackathonComponents/ProjectCard";
import { FilterProjects } from "../../Components/HackathonComponents/FilterProjects";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import { QueryErrorResetBoundary, useQuery } from "@tanstack/react-query";

import moment from "moment";
import { useMediaQuery } from "@mantine/hooks";

import { MobileMenuNew } from "../../Components/Layout/Navbar/Menu";

export const MainHackathon = ({ props }) => {
  // Data from params and context
  const { id } = useParams();
  const ref = useRef();
  const { user } = useContext(AppContext);

  // State to keep alert message
  const [message, setMessage] = useState("");

  // State to keep hackathon data entries
  const [entryData, setEntryData] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const [userTeams, setUserTeams] = useState([]);

  // Media queries
  const xs = useMediaQuery("(max-width: 568px)");
  const sm = useMediaQuery("(max-width: 768px)");
  const sm2 = useMediaQuery("(max-width: 850px)");
  const lg = useMediaQuery("(min-width: 995px)");
  const md = useMediaQuery("(max-width: 1150px)");
  const xs3 = useMediaQuery("(max-width: 600px)");
  const xs4 = useMediaQuery("(max-width: 460px)");

  // State to manupilate modal for Team Creation
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

  // State for active tab
  const [activeTab, setActiveTab] = useState(1);

  const [member, setMember] = useState({});
  const [members, setMembers] = useState([]);
  const [team, setTeam] = useState("");
  const [errors, setErrors] = useState({ team: null, member: null });

  const getUserByEmail = async (member) => {
    const response = await axios.get(`/api/v1/user_by_email/${member}/`);
    return response?.data;
  };

  const validate = async ({ id, value }) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (id == "member") {
      if (!emailRegex.test(value))
        return setErrors({ ...errors, member: "Not in the email format" });
      try {
        const reqMember = await getUserByEmail(value);
        console.log(reqMember);
        let _members = members;
        if (_members.length == 3)
          return setErrors({
            ...errors,
            member: "Team can have up to 3 members",
          });

        if (!reqMember)
          return setErrors({ ...errors, member: "Member is not found" });

        setErrors({});
        return reqMember;
      } catch (err) {
        console.log("Error", err);
        setErrors({ ...errors, member: "Member is not found" });
        return null;
      }
    }
  };

  const changeLink = () => {
    alert(
      "Komandanız yaradıldı. Hakaton başlayarkən səhifə aktiv olacaqdır..."
    );
  };

  function addMember() {
    let _members = members;

    if (!Object.keys(member).length)
      return setErrors({
        ...errors,
        member: "You should add at least 1 more member",
      });

    if (_members.length == 3)
      return setErrors({ ...errors, member: "Team can have up to 3 members" });

    if (member.id == user.id)
      return setErrors({
        ...errors,
        member: "You can not add yourself again",
      });

    let _finded = members.find((_member) => _member?.id == member?.id);
    if (_finded?.id) {
      return setErrors({
        ...errors,
        member: "You can not add same user twice",
      });
    }

    _members.push(member);
    setMembers(_members);
    setMember({});
  }

  const handleChange = async (e) => {
    const { id, value } = e.target;

    if (id == "member") {
      setMember({});

      if (value) {
        setErrors({ ...errors, member: null });

        const member = await validate({ id, value });
        if (member) {
          setMember({ ...member, email: value });
        }
      }
    }

    if (id == "team") {
      setTeam("");

      if (value) {
        setErrors({ ...errors, team: null });
        setTeam(value);
      }
    }
  };

  async function handleSubmitTeam(e) {
    e.preventDefault();

    if (!team.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (!members.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    console.log(team);
    let teamForm = new FormData();
    teamForm.append("name", team);
    teamForm.append("description", "");
    teamForm.append("is_active", true);
    teamForm.append("hackathon", entryData?.id);
    teamForm.append("user", user?.id);

    const { data } = await axios.post("/hackathon/team_create/", teamForm);

    const { id } = data;

    let responseErrors = [];

    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      try {
        await axios.patch(
          `/hackathon/team_edit/${id}/?member_email=${member?.email}`
        );
      } catch (error) {
        responseErrors.push(error);
      }
      if (responseErrors.length) console.log("Error was happened");
      else {
        setMessage("Team was successfuly created");
        setShowCreateTeamModal(false);
        getUserTeamsLength();
      }
    }
  }

  //  if(errors?.member){
  //   setButtonDisabled(true);
  // }
  // else{
  //   setButtonDisabled(false)};
  // }

  // Style for active tab
  const activeTabStyle = {
    backgroundColor: "yellow",
    border: "1px solid black",
    marginRight: "10px",
    borderRadius: "8px",
  };

  // Get hackathon data
  useQuery(
    ["", id],
    async () => await axios.get(`/hackathon/hackathonbyid/${id}/`),
    {
      onSuccess: (data) => {
        setEntryData(data?.data[0]);
      },
    }
  );

  // Get user teams data
  const getUserTeamsLength = async () => {
    await axios
      .get(`/hackathon/user_teams/${user?.id}/`)
      .then((response) => setUserTeams(response.data.length));
  };
  getUserTeamsLength();

  // Funtion for handling next step
  const nextStep = () => {
    if (activeStep == 1 && !team.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (activeStep == 2 && !members.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (activeStep != 3) {
      setActiveStep((current) => (current < 3 ? current + 1 : current));
    }
  };

  const handleOrder = async (e) => {
    e.preventDefault();
    // Step 1: Create hackathon order
    const formXorder = new FormData();
    formXorder.append("is_free", true);
    formXorder.append("user", user?.id);
    formXorder.append("hackathon", id);
    formXorder.append("promocode", "");

    try {
      const response = await axios.post("/hackathon/create-order/", formXorder);
      console.log(response);
    } catch (error) {
      console.log(error?.response?.data);
    }

    setShowCreateTeamModal(true);
  };

  // Return main component
  return (
    <div>
      <Container size={"lg"} mt={20}>
        <MobileTab>
          <div className="mobileMenuMain">
            <MobileMenuNew />
          </div>
        </MobileTab>

        <HackathonMain>
          <div className="title">
            <Link to={"/hackathons"}>
              <ArrowNarrowLeft size={32} strokeWidth={2} color={"black"} />
            </Link>
            <div className="text">
              <a href="#">{entryData?.title}</a>
              <span>Hackathon event</span>
            </div>
          </div>
          <div className="info">
            <div className="">
              <Image
                width={lg ? "466px" : "100%"}
                radius="md"
                src={entryData?.image}
                alt="Random image"
                withPlaceholder
              />
            </div>
            <div className="about">
              <div className="text">
                <h1>About</h1>
                <p>{entryData?.description && parse(entryData?.description)}</p>
              </div>
              <div className="rules">
                <div className="badge">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12.7119 13.4598L10.6338 11.452L10.6584 11.428C12.0819 9.87595 13.0965 8.09191 13.6938 6.20406H16.0911V4.60014H10.3635V3H8.72695V4.60014H3V6.19203H12.1392C11.5869 7.73613 10.7238 9.20015 9.54574 10.4799C8.78496 9.65183 8.15496 8.7519 7.65574 7.80007H6.01922C6.61652 9.1039 7.43461 10.3359 8.4573 11.4479L4.29691 15.468L5.45461 16.6L9.54539 12.6001L12.09 15.0882L12.7119 13.4598Z"
                      fill="#333333"
                    />
                    <path
                      d="M17.7783 12H16.3464L13.125 21H14.557L15.3623 18.75H18.763L19.5683 21H21L17.7783 12ZM15.8991 17.2499L17.0625 13.9986L18.2259 17.2499H15.8991Z"
                      fill="#333333"
                    />
                  </svg>
                  <p>Azerbaijan</p>
                </div>
                <div className="badge">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 15.0005V5.50049C6 5.26479 6 5.14693 6.07322 5.07371C6.14645 5.00049 6.2643 5.00049 6.5 5.00049H16.7929C17.3066 5.00049 17.5635 5.00049 17.6274 5.15482C17.6913 5.30915 17.5097 5.49078 17.1464 5.85404L13.2828 9.71765C13.1495 9.85098 13.0828 9.91765 13.0828 10.0005C13.0828 10.0833 13.1495 10.15 13.2828 10.2833L17.1464 14.1469C17.5097 14.5102 17.6913 14.6918 17.6274 14.8462C17.5635 15.0005 17.3066 15.0005 16.7929 15.0005H6ZM6 15.0005V19.0005"
                      stroke="#484848"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p>
                    {moment(entryData?.start_date).format("DD.MM.YY")} -{" "}
                    {moment(entryData?.end_date).format("DD.MM.YY")}
                  </p>
                </div>
                {/* <div className="badge">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 8.00049C3 7.05768 3 6.58627 3.29289 6.29338C3.58579 6.00049 4.05719 6.00049 5 6.00049H15.9296C16.4536 6.00049 16.7156 6.00049 16.9367 6.11881C17.1578 6.23714 17.3031 6.45512 17.5937 6.89109L20.2604 10.8911C20.6189 11.4288 20.7981 11.6977 20.7981 12.0005C20.7981 12.3033 20.6189 12.5721 20.2604 13.1099L17.5937 17.1099C17.3031 17.5459 17.1578 17.7638 16.9367 17.8822C16.7156 18.0005 16.4536 18.0005 15.9296 18.0005H5C4.05719 18.0005 3.58579 18.0005 3.29289 17.7076C3 17.4147 3 16.9433 3 16.0005V8.00049Z"
                      stroke="#484848"
                    />
                    <circle
                      cx="15"
                      cy="12.0005"
                      r="1"
                      fill="#484848"
                      stroke="#484848"
                    />
                  </svg>
                  <p>7000 $</p>
                </div> */}
              </div>
              <div className="submitter">
                <Button
                  className="subbut"
                  onClick={!userTeams ? handleOrder : changeLink}
                >
                  {!userTeams ? "Join" : "Enter"}
                </Button>
              </div>
              <Modal
                opened={showCreateTeamModal}
                onClose={() => {
                  setShowCreateTeamModal(false);
                  setErrors({ member: null, team: null });
                  setTeam("");
                  setMember({});
                  setMembers([]);
                  setActiveStep(0);
                }}
                title="Create team"
                size={"lg"}
                my={"auto"}
              >
                <form ref={ref} onSubmit={handleSubmitTeam}>
                  <Stepper
                    color="#333"
                    active={activeStep}
                    onStepClick={setActiveStep}
                    allowNextStepsSelect={false}
                  >
                    <Stepper.Step
                      label="First step"
                      description="Read description"
                      allowStepClick={false}
                      allowStepSelect={false}
                    >
                      Welcome to the Hackathon Team Creation Portal! Before you
                      dive into creating your winning team, let's walk through
                      the process to ensure you have all the information you
                      need. In this hackathon, you'll be solving exciting
                      challenges, so teamwork is essential. You'll start by
                      defining a unique team name that represents your mission
                      and vision. Afterward, you'll have the opportunity to
                      invite team members to join you on this thrilling journey.
                      Remember, you must have at least one team member
                      (excluding the admin) to form a team. Let's get started!
                    </Stepper.Step>
                    <Stepper.Step
                      label="Second step"
                      description="Define a team name"
                    >
                      <p style={{ marginBottom: "16px" }}>
                        A team's name is not just a label; it's a symbol of your
                        identity and purpose. Choose a name that reflects your
                        team's spirit and the challenge you aim to conquer. Make
                        it creative, memorable, and inspiring. Your team name
                        will be the banner under which you'll tackle complex
                        problems and showcase your innovation. Once you've
                        decided on the perfect name, proceed to the next step to
                        gather your teammates.
                      </p>
                      <TextInput
                        placeholder="Team name"
                        id="team"
                        error={errors?.team}
                        value={team}
                        onChange={handleChange}
                      />
                    </Stepper.Step>
                    <Stepper.Step label="Final step" description="Add members">
                      <p style={{ marginBottom: "16px" }}>
                        Great teams are built on diversity, expertise, and
                        collaboration. Now that you've established your team
                        name, it's time to bring in the talented individuals who
                        will help you succeed in the hackathon. You can invite
                        up to three members to join your team. Each member will
                        contribute their unique skills and perspectives to
                        tackle the challenges ahead. Don't forget that you, as
                        the admin, are already counted as a team member.
                        Collaboration is the key to success, so choose your team
                        members wisely. Once your team is complete, you'll be
                        ready to embark on the exciting journey of the
                        hackathon!
                      </p>
                      <div>
                        <TextInput
                          id="member"
                          error={errors?.member}
                          value={member?.email}
                          onChange={handleChange}
                          placeholder="Enter email address"
                        />
                        <div
                          className="createTeam"
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{ backgroundColor: "#333" }}
                            onClick={addMember}
                          >
                            Add member
                          </Button>
                        </div>
                        <div
                          className="addedUsers"
                          style={{ marginTop: "15px" }}
                        >
                          <Grid>
                            {members?.map((member) => (
                              <Grid.Col span={4}>
                                <div
                                  className="member"
                                  key={member?.id}
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    width={50}
                                    height={50}
                                    mb={10}
                                    src={member.profile_img}
                                    withPlaceholder
                                    radius={"100%"}
                                  />
                                  <Text
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {member.first_name} {member.last_name}
                                  </Text>
                                </div>
                              </Grid.Col>
                            ))}
                          </Grid>
                        </div>
                      </div>
                      <div
                        className="createTeam"
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      ></div>
                    </Stepper.Step>
                    <Stepper.Completed>
                      Completed, now you are ready to shine!
                      {message && message}
                      <Group justify="center" mt="xl">
                        <Button
                          type="submit"
                          style={{ backgroundColor: "#333" }}
                        >
                          Submit
                        </Button>
                      </Group>
                    </Stepper.Completed>
                  </Stepper>
                </form>

                <Group
                  justify="center"
                  mt="xl"
                  style={{ display: activeStep === 3 ? "none" : "block" }}
                >
                  <Button
                    style={{ backgroundColor: "#333" }}
                    onClick={nextStep}
                  >
                    Next Step
                  </Button>
                </Group>
              </Modal>
            </div>
          </div>
        </HackathonMain>
        <YourProject>
          <div className="yourProject">
            <div className="nav">
              <div className="title">
                <p>Your project</p>
              </div>
              <div className="buttons">
                <button className="editBtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5.92971 19.283L5.92972 19.283L5.95149 19.2775L5.95151 19.2775L8.58384 18.6194C8.59896 18.6156 8.61396 18.6119 8.62885 18.6082C8.85159 18.5528 9.04877 18.5037 9.2278 18.4023C9.40683 18.301 9.55035 18.1571 9.71248 17.9947C9.72332 17.9838 9.73425 17.9729 9.74527 17.9618L16.9393 10.7678L16.9393 10.7678L16.9626 10.7445C17.2761 10.4311 17.5461 10.1611 17.7333 9.91573C17.9339 9.65281 18.0858 9.36038 18.0858 9C18.0858 8.63961 17.9339 8.34719 17.7333 8.08427C17.5461 7.83894 17.276 7.5689 16.9626 7.2555L16.9393 7.23223L16.5858 7.58579L16.9393 7.23223L16.7678 7.06066L16.7445 7.03738C16.4311 6.72395 16.1611 6.45388 15.9157 6.2667C15.6528 6.0661 15.3604 5.91421 15 5.91421C14.6396 5.91421 14.3472 6.0661 14.0843 6.2667C13.8389 6.45388 13.5689 6.72395 13.2555 7.03739L13.2322 7.06066L6.03816 14.2547C6.02714 14.2658 6.01619 14.2767 6.00533 14.2875C5.84286 14.4496 5.69903 14.5932 5.59766 14.7722C5.4963 14.9512 5.44723 15.1484 5.39179 15.3711C5.38809 15.386 5.38435 15.401 5.38057 15.4162L4.71704 18.0703C4.71483 18.0791 4.7126 18.088 4.71036 18.097C4.67112 18.2537 4.62921 18.421 4.61546 18.5615C4.60032 18.7163 4.60385 18.9773 4.81326 19.1867C5.02267 19.3961 5.28373 19.3997 5.43846 19.3845C5.57899 19.3708 5.74633 19.3289 5.90301 19.2896C5.91195 19.2874 5.92085 19.2852 5.92971 19.283Z"
                      stroke="#1A1D1C"
                    />
                    <path
                      d="M12.5 7.5L15.5 5.5L18.5 8.5L16.5 11.5L12.5 7.5Z"
                      fill="#1A1D1C"
                    />
                  </svg>
                  <p>Edit</p>
                </button>
                <button className="removeBtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 15L10 12"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M14 15L14 12"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M3 7H21V7C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7V7Z"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p>Delete</p>
                </button>
              </div>
            </div>

            <div className="main">
              <Grid>
                <Grid.Col span={sm ? 12 : 4}>
                  <Image
                    width={"100%"}
                    // height={"328px"}
                    radius="md"
                    src={null}
                    alt="Random image"
                    withPlaceholder
                  />
                </Grid.Col>
                <Grid.Col span={sm ? 12 : 8}>
                  <div className="details">
                    <h2>Project name</h2>
                    <span>Team name</span>
                    <div className="yourDescription">
                      <p>
                        Ready to grow the creative business of your dreams?
                        Whether you’re itching to go full-time freelance or
                        looking for ways to supercharge your existing biz, it’s
                        time to really make it happen. Join freelancer and
                        creative business expert Kaleigh Moore for a live,
                        two-week crash-course where you’ll learn everything you
                        need to set up (and manage!) a thriving creative
                        business that you love.
                      </p>
                    </div>
                    <div className="avatars">
                      <Avatar.Group className="avatarsGroup" spacing="sm">
                        <Avatar
                          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                          alt="avatar"
                          radius="xl"
                        />
                        <Avatar
                          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                          alt="avatar"
                          radius="xl"
                        />
                        <Avatar
                          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                          alt="avatar"
                          radius="xl"
                        />
                        <Avatar
                          src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                          alt="avatar"
                          radius="xl"
                        />
                      </Avatar.Group>
                    </div>
                  </div>
                </Grid.Col>
              </Grid>
            </div>
          </div>
        </YourProject>
        <OtherProjects>
          <div className="otherProjects">
            <div className="nav">
              <div className="title">
                <p>Other projects</p>
              </div>
              <FilterProjects />
              <Tabs defaultValue="all">
                <Tabs.List style={{ border: "none" }}>
                  <Tabs.Tab
                    value="all"
                    onClick={() => setActiveTab(1)}
                    style={
                      activeTab === 1
                        ? activeTabStyle
                        : {
                            border: "1px solid black",
                            marginRight: "10px",
                            borderRadius: "8px",
                            marginTop: "10px",
                          }
                    }
                  >
                    All
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="messages"
                    onClick={() => setActiveTab(2)}
                    style={
                      activeTab === 2
                        ? activeTabStyle
                        : {
                            border: "1px solid black",
                            marginRight: "10px",
                            borderRadius: "8px",
                            marginTop: "10px",
                          }
                    }
                  >
                    Messages
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="settings"
                    onClick={() => setActiveTab(3)}
                    style={
                      activeTab === 3
                        ? activeTabStyle
                        : {
                            border: "1px solid black",
                            marginRight: "10px",
                            borderRadius: "8px",
                            marginTop: "10px",
                          }
                    }
                  >
                    Settings
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="all" pt="xs">
                  <div className="projects">
                    <Grid>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                      <Grid.Col span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}>
                        <ProjectCard />
                      </Grid.Col>
                    </Grid>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="messages" pt="xs">
                  Messages tab content
                </Tabs.Panel>

                <Tabs.Panel value="settings" pt="xs">
                  Settings tab content
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </OtherProjects>
      </Container>
    </div>
  );
};

// Styled divs

const HackathonMain = styled.div`
  .title {
    display: flex;

    .text {
      margin-left: 16px;

      a {
        color: #1a1d1c;
        text-decoration: none !important ;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }

      span {
        display: block;
        margin-top: 4px;
        font-size: 15px;
        color: #5e5e5e;
      }
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .about {
      height: "max-content";
      .text {
        border-radius: 10px;
        background: #f8f9fa;
        margin-left: 24px;
        padding: 10px 30px;
        max-height: 100%;

        h1 {
          color: #4f4f4f;
          font-size: 22px;
        }

        p {
          text-align: justify;
          color: #333;
        }
      }

      .rules {
        display: flex;
        margin-top: 20px;

        .badge {
          margin-left: 26px;
          display: flex;
          padding: 1px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid #414a4c;

          svg {
            width: 24px;
            height: 24.001px;
          }

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .submitter {
        width: 100%;
        padding-left: 24px;
        text-align: end;
        box-sizing: border-box;

        .subbut {
          margin-top: 8px;
          width: 100%;
          padding: 0;
          height: 50px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #484848;
          color: white;
          font-size: 17px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 995px) {
    .info {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .about {
      .text {
        margin-left: 0 !important;
      }
    }
    .submitter {
      padding-left: 0 !important;
    }
  }
  @media (max-width: 568px) {
    // .info{
    width: 100%;
    // }
  }
`;

const YourProject = styled.div`
  filter: blur(10px);

  .yourProject {
    background-color: #f1f3f5;
    margin-top: 50px;
    border-radius: 8px;
    padding: 16px 30px;

    .nav {
      display: flex;
      justify-content: space-between;
      .title {
        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
        }
      }

      .buttons {
        display: flex;
        margin-top: 20px;

        .editBtn {
          background: #f2c94c;
          border-color: transparent;
          border-radius: 8px;
          color: black;
          padding: 1px 12px;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 16px;
          height: 44px;
          font-weight: 400;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .removeBtn {
          background: red;
          color: #fff;
          border-color: transparent;
          border-radius: 8px;
          padding: 1px 12px;
          font-size: 16px;
          align-items: center;
          display: flex;
          height: 44px;
          font-weight: 400;
          gap: 10px;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .main {
      margin-top: 14px;

      .details {
        padding-left: 18px;
        padding-right: 18px;

        h2 {
          color: #4f4f4f;
          font-size: 22px;
        }

        span {
          display: block;
          font-size: 17px;
          color: #5e5e5e;
          margin-top: -12px;
        }

        .yourDescription {
          height: 120px;
          overflow: hidden;
        }

        .avatars {
          display: flex;
          margin-top: 20px;

          .avatarsGroup {
            & > * {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .nav {
      flex-wrap: wrap;
    }
  }
`;

const OtherProjects = styled.div`
  z-index: 0;
  position: relative;
  &::after {
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    content: "Bu hissə hackathon bitəndə aktiv olacaq.";
    position: absolute;
    top: -30px;
    left: 0;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    color: #f5df4d;
    z-index: 200;
  }
  .otherProjects {
    margin-top: 50px;
    padding: 16px 30px;

    .title {
      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 500px) {
    .search-other-projects {
      flex-wrap: wrap;
      button {
        width: 100%;
      }
    }
  }
`;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
