import { createContext, useLayoutEffect, useState } from "react"
import { getCookie } from "./useCookie";

export const AppContext = createContext();

export let aTokenX = "";

export const AppContextProvider = (props) => {

  const [ aToken, setAToken ] = useState("");
  const [ rToken, setRToken ] = useState("");

  let [ user, setUser ] = useState(null);

  const contextValues = {
    user,
    setUser,
    aToken,
    setAToken,
    rToken,
    setRToken
  };

  useLayoutEffect(() => {

    let userData = JSON.parse(localStorage.getItem("taskooluser"))
    if (userData !== undefined) {
      userData?.id !== undefined ? setUser(userData) : setUser(null);
      setAToken(getCookie("access"))
      setRToken(getCookie("refresh"))
    }

  },[])

  useLayoutEffect(() => {
    setAToken(getCookie("access"))
    setRToken(getCookie("refresh"))
    aTokenX = getCookie("access")
  }, [aToken, user]);

  return (
    <AppContext.Provider value={contextValues}>
      {props.children}
    </AppContext.Provider>
  );
}