// import { useEffect, useState } from "react";
// import axios from "axios";
// import styled from "styled-components";
// import { Link, useNavigate } from "react-router-dom";
// import bubblesBgLeft from "../../../Assets/SVGs/bubblesBgLeft.svg";
// import bubblesBgRight from "../../../Assets/SVGs/bubblesBgRight.svg";
// import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png";
// import { object, string } from "yup";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import {
//   Button,
//   LoadingOverlay,
//   PasswordInput,
//   TextInput,
// } from "@mantine/core";
// import { DatePicker } from "@mantine/dates";
// // import { Confetti } from "tabler-icons-react";

// function Register() {
//   const [loaderVisible, setLoaderVisible] = useState(false);

//   let navigate = useNavigate();

//   const phoneRegExp =
//     /^\s*(?:\+?(\d{1,4}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3})(?: *x(\d+))?\s*$/;

//   const registerValidation = object({
//     name: string().required("Name must be entered"),
//     surname: string().required("Surname must be entered"),
//     email: string()
//       .email("Email must be entered correctly")
//       .required("Email must be entered"),
//     number: string()
//       .matches(phoneRegExp, "The number must be entered correctly")
//       .required("The number must be entered"),
//     password: string()
//       .required("The password must be entered")
//       .min(4, "The password must consist of 4 digits")
//       .max(32, "The password cannot exceed 32 characters"),
//   });

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//   } = useForm({
//     resolver: yupResolver(registerValidation),
//   });

//   useEffect(() => {
//     let emailInput = document.getElementById("name");
//     emailInput?.focus();
//   }, []);

//   const formSubmit = async (e) => {
//     setLoaderVisible(true);

//     const formX = new FormData();
//     formX.append("first_name", e.name);
//     formX.append("last_name", e.surname);
//     formX.append("email", e.email);
//     formX.append("number", e.number);
//     formX.append("password", e.password);
//     formX.append("birthday", e.birthday);
//     formX.append("speciality", "");
//     formX.append("speciality_level", "");
//     formX.append("bio", "");

//     try {
//       const response = await axios.post("api/v1/register/", formX);
//       if (response.status === 201) {
//         // notifications.showNotification({
//         //   icon: <Confetti />,
//         //   title: "Congratulations!",
//         //   message: "You have successfully registered",
//         //   color: "teal",
//         // });
//         setTimeout(() => {
//           setLoaderVisible(false);
//           navigate("/login");
//         }, 2000);
//       }
//     } catch (error) {
//       setLoaderVisible(false);
//     }
//   };

//   return (
//     <RegisterDiv>
//       <div className="nav">
//         <Link to="/">
//           <img className="logo" src={taskilledShadowLogo} alt="Logo" />
//         </Link>
//         <div>
//           <h3>You already have an account?</h3>
//           <Link to="/login">Log in</Link>
//         </div>
//       </div>
//       <img src={bubblesBgLeft} alt="" />
//       <img src={bubblesBgRight} alt="" />
//       <div className="wrapper">
//         <div className="welcome">
//           <h2>Welcome!</h2>
//           <h3>Open the door to a new world with Taskilled</h3>
//         </div>
//         <form onSubmit={handleSubmit((e) => formSubmit(e))}>
//           <LoadingOverlay
//             visible={loaderVisible}
//             loaderProps={{ color: "var(--yellow)" }}
//           />

//           <TextInput
//             required
//             size="md"
//             label="Name"
//             placeholder="Name"
//             id="name"
//             {...register("name")}
//             error={errors?.name && errors?.name.message}
//           />

//           <TextInput
//             required
//             size="md"
//             label="Surname"
//             placeholder="Surname"
//             id="surname"
//             {...register("surname")}
//             error={errors?.surname && errors?.surname.message}
//           />

//           <TextInput
//             required
//             size="md"
//             label="E-mail address"
//             placeholder="E-mail adress"
//             id="email"
//             {...register("email")}
//             error={errors?.email && errors?.email.message}
//           />
//           {/* {emailTaken && <p className='error'>It is already registered with this e-mail address.</p>} */}

//           <TextInput
//             required
//             size="md"
//             label="Contact number"
//             placeholder="Contact number"
//             id="number"
//             {...register("number")}
//             error={errors?.number && errors?.number.message}
//           />

//           <DatePicker
//             required
//             id="birthday"
//             // name="birthday"
//             label="Birthday"
//             placeholder="Pick your birth date"
//             inputFormat="YYYY-MM-DD"
//             labelFormat="YYYY-MM"
//             {...register("birthday")}
//           />

//           <PasswordInput
//             className="pswd"
//             label="Password"
//             required
//             size="md"
//             placeholder="Password"
//             id="password"
//             {...register("password")}
//             toggleTabIndex={0}
//             error={errors?.password && errors?.password.message}
//           />

//           <p>
//             By registering you are agreeing to our{" "}
//             <Link to="/terms-of-usage">terms of service</Link> and{" "}
//             <Link to="/privacy_policy" href="">
//               privacy policy
//             </Link>
//           </p>

//           <Button className="tsbutton" fullWidth type="submit">
//             Register
//           </Button>
//         </form>
//       </div>
//       <div className="login">
//         <hr />
//         <h3>Already have an account?</h3>
//         <Link to="/login">Log in</Link>
//       </div>
//     </RegisterDiv>
//   );
// }

// export default Register;

// const RegisterDiv = styled.div`
//   background-color: #fff;
//   position: relative;
//   overflow-x: hidden;
//   a {
//     color: #2f80ed;
//     text-decoration: none;
//   }
//   .nav {
//     width: 90%;
//     margin: 0 auto;
//     display: flex;
//     justify-content: space-between;
//     padding: 1.6rem 0;
//     img {
//       width: 170px;
//     }
//     div {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       h3 {
//         font-weight: 400;
//         font-size: 17px;
//         margin-right: 1rem;
//       }
//       a {
//         font-weight: 500;
//       }
//     }
//   }
//   img:nth-child(2) {
//     position: absolute;
//     left: 8%;
//     top: 53%;
//   }
//   img:nth-child(3) {
//     position: absolute;
//     right: 8%;
//     top: 12%;
//   }
//   p {
//     text-align: center;
//     font-size: 15px;
//     padding: 0 2rem;
//     color: #333;
//   }
//   .login {
//     display: none;
//   }
//   .wrapper {
//     width: 27%;
//     min-width: 350px;
//     margin: 0 auto;
//     margin-bottom: 7rem;
//     .pswd {
//       button {
//         margin-right: 1rem;
//       }
//     }
//     .welcome {
//       text-align: center;
//       padding: 0.5rem 0;
//       h2 {
//         font-size: 26px;
//         font-weight: 500;
//       }
//       h3 {
//         font-size: 20px;
//         font-weight: 500;
//         color: #888;
//       }
//     }
//     form {
//       display: flex;
//       flex-direction: column;
//       grid-gap: 1rem;
//       .error {
//         display: flex;
//         width: 100%;
//         padding: 0;
//         margin: 0;
//         margin-bottom: 1rem;
//         justify-content: flex-start;
//         color: #ff0000;
//       }
//       .textInput {
//         display: flex;
//         input {
//           background-color: ${(props) => props.bg || "#f2f2f2"};
//           color: ${(props) => props.color || "#000"};
//           width: 100%;
//           position: relative;
//           display: flex;
//           font-size: ${(props) => props.fontSize || "16px"};
//           border: none;
//           padding: 1.2rem 1.4rem;
//           border-radius: 0.4rem;
//           margin-bottom: 1rem;
//           &:focus {
//             outline: 1px solid #ccc;
//           }
//         }
//       }
//       .passwordInput {
//         display: flex;
//         width: 100%;
//         position: relative;
//         input {
//           background-color: ${(props) => props.bg || "#f2f2f2"};
//           color: ${(props) => props.color || "#000"};
//           width: 100%;
//           font-size: ${(props) => props.fontSize || "16px"};
//           border: none;
//           padding: 1.2rem 1.4rem;
//           border-radius: 0.4rem;
//           margin-bottom: 1rem;
//           &:focus {
//             outline: 1px solid #ccc;
//           }
//         }
//         div {
//           position: absolute;
//           right: 0;
//           padding: 1.2rem 1.4rem;
//           cursor: pointer;
//         }
//       }
//     }
//   }
//   @media (max-width: 650px) {
//     img:nth-child(n + 2) {
//       display: none;
//     }
//     p {
//       padding-bottom: 2rem;
//     }
//     .wrapper {
//       .welcome {
//         padding: 1rem 0;
//       }
//     }
//   }
//   @media (max-width: 500px) {
//     .nav {
//       div {
//         h3 {
//           margin: 0;
//         }
//         display: flex;
//         flex-direction: column;
//         align-items: flex-end;
//         text-align: right;
//       }
//     }
//     .wrapper {
//       min-width: 280px;
//     }
//   }
//   @media (max-width: 420px) {
//     .nav {
//       div {
//         display: none;
//       }
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//     }
//     .login {
//       display: block;
//       text-align: center;
//       padding-bottom: 3rem;
//       hr {
//         width: 75%;
//         border: none;
//         margin: 0 auto 2rem auto;
//         border-bottom: 1px solid #e6e6e6;
//       }
//       h3 {
//         font-weight: 400;
//         font-size: 17px;
//       }
//       a {
//         font-weight: 500;
//       }
//     }
//     .wrapper {
//       min-width: 280px;
//     }
//   }
//   @media (max-width: 400px) {
//     .wrapper {
//       min-width: 280px;
//     }
//   }
//   @media (max-width: 300px) {
//     .wrapper {
//       min-width: 250px;
//     }
//   }
// `;

import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import bubblesBgLeft from "../../../Assets/SVGs/bubblesBgLeft.svg";
import bubblesBgRight from "../../../Assets/SVGs/bubblesBgRight.svg";
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  LoadingOverlay,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
// import {
//   NotificationsProvider,
//   showNotification,
// } from "@mantine/notifications";
// import { Confetti } from "tabler-icons-react";

function Register() {
  const [loaderVisible, setLoaderVisible] = useState(false);

  let navigate = useNavigate();

  const phoneRegExp =
    /^\s*(?:\+?(\d{1,4}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3})(?: *x(\d+))?\s*$/;

  const registerValidation = object({
    name: string().required("Name must be entered"),
    surname: string().required("Surname must be entered"),
    email: string()
      .email("Email must be entered correctly")
      .required("Email must be entered"),
    number: string()
      .matches(phoneRegExp, "The number must be entered correctly")
      .required("The number must be entered"),
    password: string()
      .required("The password must be entered")
      .min(4, "The password must consist of 4 digits")
      .max(32, "The password cannot exceed 32 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(registerValidation),
  });

  useEffect(() => {
    let emailInput = document.getElementById("name");
    emailInput?.focus();
  }, []);

  const formSubmit = async (e) => {
    setLoaderVisible(true);

    const formX = new FormData();
    formX.append("first_name", e.name);
    formX.append("last_name", e.surname);
    formX.append("email", e.email);
    formX.append("number", e.number);
    formX.append("password", e.password);
    formX.append("birthday", e.birthday);
    formX.append("speciality", "");
    formX.append("speciality_level", "");
    formX.append("bio", "");

    try {
      const response = await axios.post("api/v1/register/", formX);
      // if (response.status === 201) {
      // showNotification({
      //   icon: <Confetti />,
      //   title: "Congratulations!",
      //   message: "You have successfully registered",
      //   color: "teal",
      // });
      setTimeout(() => {
        setLoaderVisible(false);
        navigate("/login");
      }, 2000);
      // }
    } catch (error) {
      setLoaderVisible(false);
    }
  };

  return (
    // <NotificationsProvider>
    <RegisterDiv>
      <div className="nav">
        <Link to="/">
          <img className="logo" src={taskilledShadowLogo} alt="Logo" />
        </Link>
        <div>
          <h3>You already have an account?</h3>
          <Link to="/login">Log in</Link>
        </div>
      </div>
      <img src={bubblesBgLeft} alt="" />
      <img src={bubblesBgRight} alt="" />
      <div className="wrapper">
        <div className="welcome">
          <h2>Welcome!</h2>
          <h3>Open the door to a new world with Taskilled</h3>
        </div>
        <form onSubmit={handleSubmit((e) => formSubmit(e))}>
          <LoadingOverlay
            visible={loaderVisible}
            loaderProps={{ color: "var(--yellow)" }}
          />

          <TextInput
            required
            size="md"
            label="Name"
            placeholder="Name"
            id="name"
            {...register("name")}
            error={errors?.name && errors?.name.message}
          />

          <TextInput
            required
            size="md"
            label="Surname"
            placeholder="Surname"
            id="surname"
            {...register("surname")}
            error={errors?.surname && errors?.surname.message}
          />

          <TextInput
            required
            size="md"
            label="E-mail address"
            placeholder="E-mail adress"
            id="email"
            {...register("email")}
            error={errors?.email && errors?.email.message}
          />
          {/* {emailTaken && <p className='error'>It is already registered with this e-mail address.</p>} */}

          <TextInput
            required
            size="md"
            label="Contact number"
            placeholder="Contact number"
            id="number"
            {...register("number")}
            error={errors?.number && errors?.number.message}
          />

          <DatePicker
            required
            id="birthday"
            name="birthday"
            label="Birthday"
            placeholder="Pick your birth date"
            inputFormat="YYYY-MM-DD"
            labelFormat="YYYY-MM"
            {...register("birthday")}
          />

          <PasswordInput
            className="pswd"
            label="Password"
            required
            size="md"
            placeholder="Password"
            id="password"
            {...register("password")}
            toggleTabIndex={0}
            error={errors?.password && errors?.password.message}
          />

          <p>
            By registering you are agreeing to our{" "}
            <Link to="/terms-of-usage">terms of service</Link> and{" "}
            <Link to="/privacy_policy" href="">
              privacy policy
            </Link>
          </p>

          <Button className="tsbutton" fullWidth type="submit">
            Register
          </Button>
        </form>
      </div>
      <div className="login">
        <hr />
        <h3>Already have an account?</h3>
        <Link to="/login">Log in</Link>
      </div>
    </RegisterDiv>
    // </NotificationsProvider>
  );
}

export default Register;

const RegisterDiv = styled.div`
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  a {
    color: #2f80ed;
    text-decoration: none;
  }
  .nav {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    img {
      width: 170px;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-weight: 400;
        font-size: 17px;
        margin-right: 1rem;
      }
      a {
        font-weight: 500;
      }
    }
  }
  img:nth-child(2) {
    position: absolute;
    left: 8%;
    top: 53%;
  }
  img:nth-child(3) {
    position: absolute;
    right: 8%;
    top: 12%;
  }
  p {
    text-align: center;
    font-size: 15px;
    padding: 0 2rem;
    color: #333;
  }
  .login {
    display: none;
  }
  .wrapper {
    width: 27%;
    min-width: 350px;
    margin: 0 auto;
    margin-bottom: 7rem;
    .pswd {
      button {
        margin-right: 1rem;
      }
    }
    .welcome {
      text-align: center;
      padding: 0.5rem 0;
      h2 {
        font-size: 26px;
        font-weight: 500;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        color: #888;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      .error {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-bottom: 1rem;
        justify-content: flex-start;
        color: #ff0000;
      }
      .textInput {
        display: flex;
        input {
          background-color: ${(props) => props.bg || "#f2f2f2"};
          color: ${(props) => props.color || "#000"};
          width: 100%;
          position: relative;
          display: flex;
          font-size: ${(props) => props.fontSize || "16px"};
          border: none;
          padding: 1.2rem 1.4rem;
          border-radius: 0.4rem;
          margin-bottom: 1rem;
          &:focus {
            outline: 1px solid #ccc;
          }
        }
      }
      .passwordInput {
        display: flex;
        width: 100%;
        position: relative;
        input {
          background-color: ${(props) => props.bg || "#f2f2f2"};
          color: ${(props) => props.color || "#000"};
          width: 100%;
          font-size: ${(props) => props.fontSize || "16px"};
          border: none;
          padding: 1.2rem 1.4rem;
          border-radius: 0.4rem;
          margin-bottom: 1rem;
          &:focus {
            outline: 1px solid #ccc;
          }
        }
        div {
          position: absolute;
          right: 0;
          padding: 1.2rem 1.4rem;
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: 650px) {
    img:nth-child(n + 2) {
      display: none;
    }
    p {
      padding-bottom: 2rem;
    }
    .wrapper {
      .welcome {
        padding: 1rem 0;
      }
    }
  }
  @media (max-width: 500px) {
    .nav {
      div {
        h3 {
          margin: 0;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }
    .wrapper {
      min-width: 280px;
    }
  }
  @media (max-width: 420px) {
    .nav {
      div {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .login {
      display: block;
      text-align: center;
      padding-bottom: 3rem;
      hr {
        width: 75%;
        border: none;
        margin: 0 auto 2rem auto;
        border-bottom: 1px solid #e6e6e6;
      }
      h3 {
        font-weight: 400;
        font-size: 17px;
      }
      a {
        font-weight: 500;
      }
    }
    .wrapper {
      min-width: 280px;
    }
  }
  @media (max-width: 400px) {
    .wrapper {
      min-width: 280px;
    }
  }
  @media (max-width: 300px) {
    .wrapper {
      min-width: 250px;
    }
  }
`;
