import { Image, Tabs, Text } from "@mantine/core";
import { PromoCode } from "./PromoCode";
import { Ulduzum } from "./Ulduzum";
import ulduzumLogo from "../../Assets/Images/ulduzum.png"
import { Tag } from "tabler-icons-react";

export function PromoTabs({ props }){

  const { type, links, texts, entryData, coursePrice, sections, purchased, courseIsFree, userLoggedIn, user, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, setCoursePrice, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo } = props

  function emptyFields(){

    setPromoEntered("")
    setPromoResponse(null)
    setPromoDoesNotExist(false)

    setPhoneNumber("")
    setNoUlduzumPromo(false)
    setUlduzumResponse(false)

    setCoursePrice(entryData?.price)

  }

  return(
    <Tabs defaultValue="promo" onTabChange={() => emptyFields()}  radius={0} mt={0} variant="pills" styles={{ tabsList:{ gap:0 }, tab:{ backgroundColor:"#F8F9FA", border:"2px solid #F8F9FA" } }}>

      <Tabs.List grow>
        <Tabs.Tab icon={<Tag size={24}/>} style={{ color:"black", borderRadius:".25rem 0 0 .25rem" }} p={8} color={"white"} value="promo"><Text size={17} weight={500}>Promo</Text></Tabs.Tab>
        <Tabs.Tab icon={<Image width={25} height={25} src={ulduzumLogo}/>} style={{ color:"black", borderRadius:"0 .25rem .25rem 0" }} p={8} color={"white"} value="ulduzum"><Text size={17} weight={500}>Ulduzum</Text></Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="promo" pt="sm">
        <PromoCode props={{ type, links, texts, entryData, coursePrice, sections, purchased, courseIsFree, userLoggedIn, user, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, setCoursePrice }}/>
      </Tabs.Panel>

      <Tabs.Panel value="ulduzum" pt="sm">
        <Ulduzum props={{ links, entryData, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo, setCoursePrice }}/>
      </Tabs.Panel>

    </Tabs>
  )
}