import axios from "axios";
import { Button, TextInput } from "@mantine/core";
import { PromoStyle } from "./PaymentStyle";

export function PromoCode({ props }) {

  const { links, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, entryData, coursePrice, setCoursePrice, sections } = props;

  async function checkPromo(e){
    e.preventDefault();

    if(promoEntered){
      const response = await axios.get(links.get_promocode);

      if(promoDoesNotExist === true){
        setPromoDoesNotExist(false);
      }

      setPromoResponse(response.data);
      if(response?.data?.price){
        setCoursePrice(response?.data?.price)
      } else {
        setCoursePrice(entryData?.price)
      }

      if(response.status === 204){
        setPromoDoesNotExist(true);
      }
    }
  }

  return(
    <PromoStyle>

      {
        entryData?.id === 13 &&
        <p> Promo kodu daxil et, daha az ödə </p>
      }

      <form onSubmit={(e) => checkPromo(e)}>
        <TextInput
          id="promo"
          value={promoEntered}
          placeholder="Enter promo code"
          onChange={(e) => setPromoEntered(e.target.value)}
        />
        <Button variant="default" onClick={(e) => checkPromo(e)}>Check</Button>
      </form>

      {
        (promoDoesNotExist || promoResponse) &&
        <div className="submit">
          { promoDoesNotExist && <h3 className="noPromo">This promo code does not exist</h3>}
          { promoResponse && <h3 className="yesPromo">{promoResponse?.promocode[0]?.discount_percent}% of discount applied!</h3>}
        </div>
      }

    </PromoStyle>
  )
}