import styled from "styled-components";
import taskilledShadowLogo from "../../Assets/Images/taskilledShadowLogo.png"
import { Link, useLocation } from "react-router-dom";
import { BrandFacebook, BrandInstagram, BrandLinkedin, BrandTelegram, BrandYoutube, Location, Mail, Phone } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { Partners } from "../Partners";

export const Footer = () => {

  const { t } = useTranslation()

  let location = useLocation()

  return (
    <FooterStyle>
      {/* {
        location?.pathname === "/" &&
        <Partners props={{ showHeader:false }}/>
      } */}
      <div className="extended">
        <div className="first">
          <Link to="/">
            <img src={taskilledShadowLogo} alt="logo" />
          </Link>
          <div className="links">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/taskilled/"><BrandLinkedin/></a>
            <a target="_blank" rel="noreferrer" href="https://t.me/taskilled/"><BrandTelegram/></a>
            <a target="_blank" rel="noreferrer" href="https://instagram.com/taskilledcom"><BrandInstagram/></a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Taskilled"><BrandFacebook/></a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=m9h6zMupcls"><BrandYoutube/></a>
          </div>
        </div>
        <div className="second">
          <h3>{t("menu")}</h3>
          <Link to="/">{t("home")}</Link>
          <Link to="/about">{t("about")}</Link>
          <Link to="/partners">{t("our partners")}</Link>
        </div>
        <div className="third">
          <h3>{t("help")}</h3>
          <Link to="/faq">{t("faq")}</Link>
          <Link to="/howitworks">{t("how it works")}</Link>
        </div>
        <div className="forth">
          <h3>{t("contact")}</h3>
          <div><span className="location"><Location/> </span><p>{t("address")}</p></div>
          <div><span><Mail/> </span><p>info@taskilled.com</p></div>
          <div><span><Phone/> </span><p>+994 77 567 41 11</p></div>
        </div>
      </div>
      <div className="miniFooter">
        <div className="miniWrapper">
          <p>{t("rights")}</p>
          <div>
            <Link to="/terms-of-usage">{t("terms and conditions")}</Link>
            <Link to="/privacy_policy">{t("privacy policy")}</Link>
          </div>
        </div>
      </div>
    </FooterStyle>
  );
};

const FooterStyle = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  color: #727272;
  background-color: #fff;
  border-top: 1px solid #eee;
  p {
    font-size: 17px;
  }
  a {
    font-size: 17px;
    margin-left: 2rem;
    text-decoration: none;
    color: #727272;
  }
  .extended{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0.5rem 0;
    width: 90%;
    margin: 0 auto;
    h3{
      font-weight: 500;
      color: #4f4f4f;
    }
    div{
      margin: 4rem 0 3rem 0;
    }
    .first{
      img{
        margin-top: .5rem;
        width: 170px;
        object-fit: cover;
      }
      a{
        margin-left: 0;
      }
      .links{
        display: flex;
        flex-wrap: wrap;
        width: 200px;
        margin: 2rem 0rem;
        justify-content: center;
        a{
          margin: .75rem 0;
          margin-right: 2rem;
        }
      }
    }
    .second, .third{
      display: flex;
      flex-direction: column;
      a{
        margin: .6rem 0;
      }
    }
    .forth{
      max-width: 300px;
      div{
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        span{
          margin-right: 1rem;
          margin-top: .4rem;
        }
        p{
          margin: 0;
        }
      }
    }
  }
  .miniFooter{
    border-top: 1px solid #eee;
    padding: 0.5rem 0;
    .miniWrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 800px) {
    display: block;
    padding: 0rem;
    padding-bottom: 0rem;
    margin: 0;
    text-align: center;
    .miniFooter{
      display: flex;
      flex-direction: column;
      padding: 0 1rem 1.5rem 1rem;
      margin-top: 0rem;
      a{
        margin: 0.5rem;
      }
      div{
        margin: 2rem 0 1rem 0;
        display: flex;
        flex-direction: column;
      }
      p{
        order: 2;
        margin-bottom: 0;
      }
      .miniWrapper{
        flex-direction: column;
      }
    }
    .extended{
      grid-template-columns: 1fr;
      padding: 0;
      div{
        margin: .5rem 0;
      }
      .first{
        margin-top: 2rem;
        .links{
          display: flex;
          justify-content: center;
          margin: 2rem auto 1rem auto;
          a{
            margin: .75rem 1rem;
          }
        }
      }
      .forth{
        width: 80%;
        margin: 1rem auto;
        div{
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
`;