import styled from "styled-components";
import { Quiz } from "../Quiz/Quiz";
import parse from 'html-react-parser';
import { UnpurchasedSection } from "./UnpurchasedSection";
import AnswerSection from "../UserAnswer";

export const Material = ({ props }) => {

  const { courseData, currentSection, showPart, singleSectionContentFiltered } = props

  return (
    <MaterialStyle>
      {
        currentSection.section_active !== true ?
        <UnpurchasedSection props={{ courseData, currentSection }}/>
        :
        singleSectionContentFiltered.map(content => {
          return(
            // content.type === "quiz" ?
            (showPart.id === content.id && showPart.type === content.type && showPart.type === "quiz") ?
            <Quiz props={{content}} key={content.id}/>
            :
            (showPart.id === content.id && showPart.type === content.type) &&
            <div key={content.id} className="materialInside">
              {
                content.type === "video" &&
                <iframe className="videoFrame" width="100%" height="540" src={content.file} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"/>
              }
              <div className="headerMaterial">
                <h2>{content.title}</h2>
                {parse(content.description)}
              </div>
              {
                (content.type === "chart" && (content?.file.includes("png") || content?.file.includes("jpg") || content?.file.includes("jpeg") || content?.file.includes("webp") ) ) ?
                <img src={content.file} alt="" />
                :
                content.type !== "video" &&
                <a href={content.file} target="_blank" rel="noopener noreferrer">
                  {content.file}
                </a>
              }
              {
                content.is_question &&
                <AnswerSection props={{ showPart, section:singleSectionContentFiltered}} />
              }
            </div>
          )
        })
      }
    </MaterialStyle>
  )
}

const MaterialStyle = styled.div`
  margin-top: -1rem;
  .materialInside{
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .headerMaterial{
    p{
      width: 80%;
      font-size: 17px;
    }
  }
  .question{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    hr{
      width: 100%;
      border: none;
      border-bottom: 1px solid #aaa;
      margin-top: 0;
    }
    form{
      textarea{
        width: 96.5%;
        padding: 1rem;
        border-radius: 0.25rem;
        font-size: 15px;
        resize: none;
      }
      button{
        margin-top: 1rem;
        font-size: 15px;
        background-color: var(--yellow);
        padding: .5rem 2rem;
        border: none;
        &:hover{
          background-color: #e9cd16;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .videoFrame{
      height: 210px;
      width: 100%;
      margin-top: 1.25rem;
    }
  }
`