import React, { ReactDOM } from "react";
import styled from "styled-components";
import { Container, Avatar, Grid } from "@mantine/core";
import { ArrowNarrowLeft } from "tabler-icons-react";
import { Carousel } from "react-responsive-carousel";
import PressTry from "../../Assets/Images/prestry.jpeg";
import { Rating } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const HackathonProject = () => {
  const md = useMediaQuery("(max-width: 850px)");
  const sm = useMediaQuery("(max-width: 568px)");
  return (
    <div>
      <Container size={"lg"} mt={20}>
        <ProjectMain>
          <div className="title">
            <ArrowNarrowLeft size={32} strokeWidth={2} color={"black"} />
            <div className="text">
              <a href="">Project name</a>
              <span>Hackathon project</span>
            </div>
          </div>
          <div className="projectCarousel">
            <Carousel
              className="carousel"
              Carousel
              infiniteLoop
              autoPlay
              showIndicators={false}
              showStatus={false}
              showThumbs={true}
              showArrows={false}
            >
              <div>
                <img
                  fit="contain"
                  style={{ borderRadius: "8px" }}
                  width={"100%"}
                  src={PressTry}
                />
              </div>
              <div>
                <img
                  fit="contain"
                  style={{ borderRadius: "8px" }}
                  width={"100%"}
                  src={PressTry}
                />
              </div>
              <div>
                <img
                  fit="contain"
                  style={{ borderRadius: "8px" }}
                  width={"100%"}
                  src={PressTry}
                />
              </div>
            </Carousel>
          </div>
          <div className="description">
            <h1>Description</h1>
            <p>
              Ready to grow the creative business of your dreams? Whether you’re
              itching to go full-time freelance or looking for ways to
              supercharge your existing biz, it’s time to really make it happen.
              Join freelancer and creative business expert Kaleigh Moore for a
              live, two-week crash-course where you’ll learn everything you need
              to set up (and manage!) a thriving creative business that you
              love. As a part of this exclusive learning series, you’ll master
              the most intimidating elements of running a business, gain
              actionable pricing strategies & techniques that will allow you to
              charge more for your work, and ultimately gain more confidence as
              a business owner so you can spend more time focusing on what you
              do best—creating.
            </p>
          </div>
          <div className="teamMembers">
            <h1>Team members</h1>
            <div className="members">
              <Grid>
                <Grid.Col span={sm ? 12 : md ? 6 : 3}>
                  <div className="member">
                    <Avatar
                      className="avatar"
                      src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                      alt="user"
                    />
                    <div className="info">
                      <h1>Name Surname</h1>
                      <p>Game designer</p>
                    </div>
                  </div>
                </Grid.Col>
                <Grid.Col span={sm ? 12 : md ? 6 : 3}>
                  <div className="member">
                    <Avatar
                      className="avatar"
                      src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                      alt="user"
                    />
                    <div className="info">
                      <h1>Name Surname</h1>
                      <p>Game designer</p>
                    </div>
                  </div>
                </Grid.Col>
                <Grid.Col span={sm ? 12 : md ? 6 : 3}>
                  <div className="member">
                    <Avatar
                      className="avatar"
                      src="https://avatars.githubusercontent.com/u/10353856?s=460&u=88394dfd67727327c1f7670a1764dc38a8a24831&v=4"
                      alt="user"
                    />
                    <div className="info">
                      <h1>Name Surname</h1>
                      <p>Game designer</p>
                    </div>
                  </div>
                </Grid.Col>
              </Grid>
            </div>
          </div>
          <div className="rating">
            <div className="rates">
              <div className="rate">
                <p>Creativity:</p>
                <Rating className="rateIt" value={3.5} fractions={2} />
              </div>
              <div className="rate">
                <p>Innovative:</p>
                <Rating className="rateIt" value={5} fractions={2} />
              </div>
              <div className="rate">
                <p>Presentation:</p>
                <Rating className="rateIt" value={2} fractions={2} />
              </div>
            </div>
            <button>Vote</button>
          </div>
        </ProjectMain>
      </Container>
    </div>
  );
};

const ProjectMain = styled.div`
  .title {
    display: flex;

    .text {
      margin-left: 16px;

      a {
        color: #1a1d1c;
        text-decoration: none !important ;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }

      span {
        display: block;
        margin-top: 4px;
        font-size: 15px;
        color: #5e5e5e;
      }
    }
  }

  .projectCarousel {
    margin-top: 30px;
    .carousel {
      width: 880px;
    }
  }

  .description {
    margin-top: 30px;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #4f4f4f;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      color: #333;
      text-align: justify;
    }
  }

  .teamMembers {
    margin-top: 20px;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #4f4f4f;
    }

    .members {
      .member {
        display: flex;
        margin-bottom: 30px;

        .avatar {
          width: 64px;
          height: 64px;
          flex-shrink: 0;
        }

        .info {
          margin-left: 10px;

          h1 {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            color: #1a1d1c;
          }

          p {
            color: #5e5e5e;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
            margin-top: -10px;
          }
        }
      }
    }
  }

  .rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .rates {
      border-radius: 8px;
      background: #fff7e0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .rate {
        display: flex;
        align-items: center;

        p {
          margin-left: 20px;
          margin-right: 4px;
          color: #1a1d1c;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }

        .rateIt {
          margin-right: 26px;
        }
      }
    }

    button {
      width: 300px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #484848;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }
  }

  @media (max-width: 1050px) {
    .rating {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  @media (max-width: 995px) {
    .projectCarousel {
      .carousel {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .rating {
      .rates {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
`;
