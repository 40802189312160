import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { Post } from './Post'
import { Button, Center } from '@mantine/core'
import { DotsVertical, Refresh } from 'tabler-icons-react'
import { useInterval } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { AllCommunities } from './Communities/AllCommunities'
import { useParams } from 'react-router-dom'

export const AllPosts = ({ props }) => {

  const { id:community } = useParams()

  let link = community ? `/blog/community_posts/${community}/` : `/blog/list/`

  const { data:feed, refetch, isLoading } = useQuery([`feed`],
    async () => await axios.get(link).then(res => res.data)
  )
  
  const [ showRefreshButton, setShowRefreshButton ] = useState(false)

  const interval = useInterval(() => setShowRefreshButton(true), 600000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  const queryClient = useQueryClient()

  function refreshFeed(){
    queryClient.invalidateQueries(["feed"])
    if(isLoading === false){
      setShowRefreshButton(false)
    }
  }

  return (
    <div style={{ display:"flex", flexDirection:"column", gap:".5rem" }}>
      {
        showRefreshButton &&
        <Center style={{ display:"flex", flexDirection:"column", gap:"0" }}>
          <DotsVertical size={15} color='#ddd'/>
          <Button m={"xs"} color='gray.7' loading={isLoading} onClick={() => refreshFeed()} radius={"xl"} styles={{ root:{ width:"120px", margin:"1rem auto" } }} leftIcon={<Refresh/>}>Refresh</Button>
          <DotsVertical size={15} color='#ddd'/>
        </Center>
      }
      { feed?.map(post => <Post key={post?.id} props={{ post, refetch }}/>) }
    </div>
  )
}
