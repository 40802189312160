import styled from "styled-components"
import parse from 'html-react-parser'
import AnswerSection from "./AnswerSection"

export const Material = ({ props }) => {

  const { type, studentToTest, currentSection, showPart, currentSectionEntries } = props

  return (
    <MaterialStyle>
      {
        currentSectionEntries?.map(content => {
          return(
            (showPart?.entry?.id === content?.id && showPart?.entry?.type === content?.type && showPart?.entry?.type === "quiz") ?
            // <Quiz props={{content}} key={content?.id}/>
            ""
            :
            (showPart?.entry?.id === content?.id && showPart?.entry?.type === content?.type) &&
            <div key={content?.id} className="materialInside">

              {
                content?.file?.includes("player") &&
                <iframe title="videox" className="videoFrame" width="100%" height="540" src={content?.file} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"/>
              }

              <div className="headerMaterial">
                <h2>{content?.title}</h2>
                {parse(content?.description)}
              </div>

              {
                (content?.file?.endsWith(".jpg") || content?.file?.endsWith(".png") || content?.file?.endsWith(".webp") || content?.file?.endsWith(".jpeg") ) &&
                <img src={content?.file} alt="" />
              }

              {
                content?.file && !content?.file?.includes("player") &&
                <a href={content?.file}>file - {content?.title}</a>
              }

              {
                content?.is_question === true &&
                <AnswerSection props={{ type, showPart, studentToTest, section:currentSection }} />
              }

            </div>
          )
        })
      }
    </MaterialStyle>
  )
}

const MaterialStyle = styled.div`
  .materialInside{
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .headerMaterial{
    p{
      width: 80%;
      font-size: 17px;
    }
  }
  .question{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    hr{
      width: 100%;
      border: none;
      border-bottom: 1px solid #aaa;
      margin-top: 0;
    }
    form{
      textarea{
        width: 96.5%;
        padding: 1rem;
        border-radius: 0.25rem;
        font-size: 15px;
        resize: none;
      }
      button{
        margin-top: 1rem;
        font-size: 15px;
        background-color: var(--yellow);
        padding: .5rem 2rem;
        border: none;
        &:hover{
          background-color: #e9cd16;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .videoFrame{
      height: 210px;
      width: 100%;
      margin-top: 1.25rem;
    }
  }
`