import axios from 'axios'
import styled from 'styled-components'
import bubblesBgLeft from "../../../Assets/SVGs/bubblesBgLeft.svg"
import bubblesBgRight from "../../../Assets/SVGs/bubblesBgRight.svg"
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png"
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Helpers/Context'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { Button, Center, Divider, Image, LoadingOverlay, Modal, PasswordInput, Text, TextInput } from '@mantine/core'
import { getCookie, setCookie } from '../../../Helpers/useCookie'
import { ForgotPassword } from '../ForgotPassword'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { DoorEnter } from 'tabler-icons-react'
import RegisterModal from '../Register/RegisterModal'

function LoginModal({ props }){

  const { openLoginModal, setOpenLoginModal } = props

  const [ wantsToRegister, setWantsToRegister ] = useState(false);
  const [ forgotPassword, setForgotPassword ] = useState(false);
  const [ wrongCredentials, setWrongCredentials ] = useState(false);
  const [ gotTokens, setGotTokens ] = useState(false);
  const [ loaderVisible, setLoaderVisible ] = useState(false);
  const { setUser } = useContext(AppContext);

  const loginScheme = yup.object().shape({
    email: yup.string().email("The email was not entered correctly").required("Email address must be entered"),
    password: yup.string().required("The password must be entered"),
  })

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(loginScheme),
  });

  useEffect(() => {
    let emailInput = document.getElementById("email");
    emailInput?.focus();
  } ,[])

  let navigate = useNavigate();

  const formSubmit = async (e) => {

    let formData = {
      "username": e.email,
      "password": e.password
    }

    // Gets JWT tokens
    try {
      setLoaderVisible(true);
      const tokens = await axios.post("api/v1/login/", formData);

      if (tokens.status === 200) {
        setCookie("access", tokens.data.access, 30);
        setCookie("refresh", tokens.data.refresh, 30);
        setGotTokens(true);
      }

    } catch (error) {
      if (error.response.status === 401) {
        setWrongCredentials(true);
      }
      setLoaderVisible(false);
    }

    reset()
  };

  useQuery(['user-data', gotTokens] ,
    async () => {
      if(gotTokens){
        const httpClient = axios.create({
          headers: {
            Authorization: `Bearer ${getCookie("access")}`
          }
        });

        return await httpClient.post("api/v1/user-data/").then(response => response.data)
      }
    },
    {
      onSuccess: (data) => {
        setLoaderVisible(false);
        localStorage.setItem('taskooluser', JSON.stringify(data));
        setUser(true);
        navigate(0);
      },
      onError: (error) => {
        setLoaderVisible(false);
        error?.response?.data?.detail && setWrongCredentials(true);
      }
    },
    {
      enabled: !!gotTokens,
    }
  );

  return (
    <Modal size={wantsToRegister ? 660 : 440} overlayBlur={5} opened={openLoginModal} radius={"md"} withCloseButton={false} onClose={() => setOpenLoginModal(false)}>
      {
        wantsToRegister === false ?

        <LoginDiv>

          <Center>
            <Link to="/"><Image width={180} className="logo" src={taskilledShadowLogo} alt="Logo" /></Link>
          </Center>

          <img src={bubblesBgLeft} alt="" />
          <img src={bubblesBgRight} alt="" />

          <div className='wrapper'>
            <div className='welcome'>
              <Text size={26} weight={500}>Welcome!</Text>
              <Text size={18} color='dimmed'>Glad to see you again</Text>
            </div>
            { forgotPassword === true && <ForgotPassword props={{setForgotPassword}}/> }
            {
              forgotPassword === false &&
              <>

                <form onSubmit={handleSubmit(e => formSubmit(e))}>
                  <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>

                  <TextInput radius={"md"} label="E-mail" required size="md" placeholder="E-mail address" id="email"
                    {...register('email')}
                    onChange={() => setWrongCredentials(false)}
                    error={errors.email && errors.email.message}
                  />

                  <PasswordInput radius={"md"} className="pswd" label="Password" required size="md" placeholder="Password" id="password"
                    {...register('password')}
                    toggleTabIndex={0}
                    error={errors.password && errors.password.message}
                  />
                  {wrongCredentials && <p className='error'>Email address or password is incorrect</p>}

                  <Button radius={"md"} styles={{ root:{ width:"fit-content", margin:"0 0 0 auto" } }} size="xs" variant="subtle" type="button" onClick={() => setForgotPassword(true)}>Forgot password?</Button>
                  <Button radius={"md"} leftIcon={<DoorEnter/>} className='tsbutton' type="submit">Log in</Button>
                </form>

              </>
            }
            <Divider mt={25} mb={20} color='#eee'/>
            <Center style={{ flexDirection:"column" }}>
              <Text>Do not have an account?</Text>
              <Button onClick={() => setWantsToRegister(true)} variant='subtle'>Sign up</Button>
              {/* <Link to="/register">Sign up</Link> */}
            </Center>
          </div>
        </LoginDiv>
        :
        <RegisterModal props={{ wantsToRegister, setWantsToRegister }}/>
      }
    </Modal>
  );
}

export default LoginModal;

const LoginDiv = styled.div`
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  a{
    color: #2f80ed;
    text-decoration: none;
  }
  .nav{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      h3{
        font-weight: 400;
        font-size: 17px;
        margin-right: 1rem;
      }
      a{
        font-weight: 500;
      }
    }
    img{
      width: 170px;
    }
  }
  img:nth-child(2){
    position: absolute;
    left: 8%;
    bottom: 0;
  }
  img:nth-child(3){
    position: absolute;
    right: 8.7%;
    top: 20.8%;
  }
  p{
    text-align: center;
    font-size: 15px;
    padding: 0 2rem;
    color: #333;
  }
  .wrapper{
    width: min(90%, 400px);
    margin: 0 auto;
    margin-bottom: 3rem;
    .welcome{
      text-align: center;
      padding: 2.5rem 0;
      h2{
        font-size: 26px;
        font-weight: 500;
      }
      h3{
        font-size: 20px;
        font-weight: 500;
        color: #888;
      }
    }
    .register{
      display: none;
    }
    form{
      
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      .pswd{
        button{
          margin-right: 1rem;
        }
      }
      .error{
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
        color: #ff0000;
      }
      .forgot{
        margin-left: auto;
        color: #2d9cdb;
        text-decoration: none;
        cursor: pointer;
        background: none;
        border: none;
        margin-top: 0;
        font-size: 15px;
      }
    }
  }
  @media (max-width: 650px){
    img:nth-child(n+2){
      display: none;
    }
  }
  @media (max-width: 500px){
    
  }
  @media (max-width: 300px){
  }
`