import axios from "axios";
import { Button, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PayForSections } from "./PayForSections";
import { FreeCourse } from "./FreeCourse";
import { useQuery } from "@tanstack/react-query";
import { SkeletonLoader } from "../Loaders/SkeletonLoader";
import { PromoTabs } from "./PromoTabs";
import { PaymentStyle } from "./PaymentStyle";

export function Payment({ props }){

  const { user, entryData, type } = props

  const [ connecting, setConnecting ] = useState(false);

  const [ promoEntered, setPromoEntered ] = useState("");
  const [ promoResponse, setPromoResponse ] = useState(null);
  const [ promoDoesNotExist, setPromoDoesNotExist ] = useState(false);

  const [ payInSection, setPayInSection ] = useState(false);
  const [ sections, setSections ] = useState([]);
  const [ noSectionChosen, setNoSectionChosen ] = useState(false);
  const [ noSections, setNoSections ] = useState(false);

  const [ coursePrice, setCoursePrice ] = useState(entryData?.price);

  const [ purchased, setPurchased ] = useState(false);
  const [ readyToPurchase, setReadyToPurchase ] = useState(false);
  const [ order, setOrder ] = useState({});

  const [ phoneNumber, setPhoneNumber ] = useState(undefined);
  const [ noUlduzumPromo, setNoUlduzumPromo ] = useState(false);
  const [ ulduzumResponse, setUlduzumResponse, ] = useState(false);

  let links = type === "internship" ?
  {
    successfuly_paid: `/internship/successfuly_paid_intern/${entryData?.id}/`,
    create_order: `/internship/create-internorder/${phoneNumber ? '?&phone=994' + phoneNumber : ''}`,
    section_list: `/internshipsection/section-list/${entryData?.id}/`,
    get_promocode: `/internship/getpromocodepercent/?intern_id=${entryData?.id}${promoEntered ? '&code=' + promoEntered : ''}${phoneNumber ? '&phone=994' + phoneNumber : ''}`,
  }
  : type === "course" ?
  {
    successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
    create_order: `/api/create-order/${phoneNumber !== undefined ? '?&phone=994' + phoneNumber : ''}`,
    section_list: `/api/section-detail/${entryData?.id}/`,
    get_promocode: `/api/getpromocodepercentforcourse/?course_id=${entryData?.id}${promoEntered ? '&code=' + promoEntered : ''}${phoneNumber ? '&phone=994' + phoneNumber : ''}`,
  }
  : type === "hackathon" && 
  {
    successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
    create_order: `/hackathon/create-order/${phoneNumber !== undefined ? '?&phone=994' + phoneNumber : ''}`,
    section_list: `/api/section-detail/${entryData?.id}/`,
    get_promocode: `/api/getpromocodepercentforcourse/?course_id=${entryData?.id}${promoEntered ? '&code=' + promoEntered : ''}${phoneNumber ? '&phone=994' + phoneNumber : ''}`,
  };

  let texts = type === "internship" ?
  {
    apply: "Apply internship",
    log_in: "Log in to apply",
    buy_full: "Buy full internship",
  }
  :
  {
    apply: "Purchase course",
    log_in: "Log in to purchase",
    buy_full: "Buy full course",
  }

  useQuery(["user owns", entryData?.id],
    async () => { return await axios.get(links.successfuly_paid).then(res => res.data) },
    {
      onSuccess: (data) => {
        if(data.successfuly_paid === true || data.id){
          setPurchased(true)
        }
      },
      enabled: !!entryData?.id,
    }
  )

  const { data:sectionList, isLoading } = useQuery(["sections list", entryData?.id],
    async () => { return await axios.get(links.section_list).then(res => res.data) }
  )

  useEffect(() => {
    if(sectionList){
      if(sectionList?.length > 0){
        let filtered = sectionList.map(section => {
          if(section.is_final_project === false){
            return {
              id: section.id,
              title: section.title,
              chosen: false,
            }
          }
        }).filter(section => section !== undefined).sort((a, b) => a.id - b.id);
        setSections(filtered)
      }else{
        setNoSections(true)
      }
    }
  }, [sectionList])

  useEffect(() => {
    if(order.url){
      localStorage.setItem("CPOrderIdTaskool", order.order_id)
      window.location = order.url
    }
  }, [order]);

  useEffect(() => {

    if(readyToPurchase === true){

      const chosenSections = sections.filter(section => section.chosen);
      const chosenSectionsIds = chosenSections.map(section => section.id);

      let formX = {
        user: user?.id,
        promocode: promoEntered,
      }
      if(type === "internship"){
        formX.intershipinfo = entryData?.id;
      }
      if(type === "course"){
        formX.course = entryData?.id;
      }
      if(type === "hackathon"){
        formX.hackathon = entryData?.id;
      }
      if(phoneNumber?.length >= 9){
        formX.is_campaign = true;
      }
      if(payInSection && chosenSectionsIds?.length > 0){
        formX.installment_paid = true;
        formX.sections = chosenSectionsIds;
      }
      if(payInSection && chosenSectionsIds?.length === 0){
        formX.installment_paid = false;
        formX.sections = [];
      }

      if(payInSection && chosenSectionsIds?.length < 1){
        setNoSectionChosen(true);
        setReadyToPurchase(false);
      } else {
        (async () => {
          setConnecting(true);
          const response = await axios.post(links.create_order , formX)
          if(response.status === 201){
            setOrder(response.data);
          }
        })();
      }

    }
  }, [readyToPurchase])

  function Price(){

    let finalPrice = sections.length > 0 ? (coursePrice / sections.length).toFixed(2) : coursePrice

    return(
      <div className="price">
        <Text size={18} color={"dimmed"}>Price per section:</Text>
        <Text size={18} color={(promoResponse || ulduzumResponse) ? "#38bf4c" : "#000"}>{finalPrice} AZN</Text>
      </div>
    )
  }

  const userLoggedIn = user?.id ? true : false;
  const courseIsFree = coursePrice < 1;

  if(isLoading){
    return (
      <PaymentStyle>
        <SkeletonLoader height={220} mb={10} visible={true}/>
      </PaymentStyle>
    )
  }

  // not logged in
  if(!userLoggedIn){
    return(
      <PaymentStyle>
        <PromoTabs props={{ type, links, texts, entryData, coursePrice, sections, purchased, courseIsFree, userLoggedIn, user, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, setCoursePrice, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo }}/>
        <Price/>
        <Button fullWidth size="lg" component={Link} className="loggedOutPayButton" to="/login">{ texts.log_in }</Button>
      </PaymentStyle>
    )
  }

  // user owns the course
  if (purchased){
    return(
      <PaymentStyle>
        <Button fullWidth color="dark" size="lg" component={Link} to={`/${type === "internship" ? "internship" : "course"}/${entryData?.id}`} className="boughtCourseButton"><Text weight={500} size={17}>Enter</Text></Button>
      </PaymentStyle>
    )
  }

  // free course
  if(courseIsFree){
    return(
      <PaymentStyle>
        <FreeCourse props={{ type, links, texts, entryData, purchased, courseIsFree, userLoggedIn, user, sections }}/>
      </PaymentStyle>
    )
  }

  // pay for sections
  if(payInSection){
    return(
      <PaymentStyle>
        <PromoTabs props={{ type, links, texts, entryData, coursePrice, sections, purchased, courseIsFree, userLoggedIn, user, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, setCoursePrice, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo }}/>
        <PayForSections props={{ type, links, texts, entryData, noSections, noSectionChosen, setNoSectionChosen, purchased, courseIsFree, userLoggedIn, connecting, setConnecting, coursePrice, setCoursePrice, user, sections, setSections, payInSection, setPayInSection, setReadyToPurchase }} />
      </PaymentStyle>
    )
  }

  return(
    <PaymentStyle>
      <PromoTabs props={{ type, links, texts, entryData, coursePrice, sections, purchased, courseIsFree, userLoggedIn, user, promoEntered, setPromoEntered, promoResponse, setPromoResponse, promoDoesNotExist, setPromoDoesNotExist, setCoursePrice, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo }}/>
      <Price/>
      <div className="payButtons">
        <Button styles={{ label:{ color:"black" } }} color={"ts"} size="lg" onClick={() => setPayInSection(true)}>Pay for sections</Button>
        <Button variant="default" size="sm" loading={connecting} onClick={() => setReadyToPurchase(true)}>Buy all sections</Button>
      </div>
    </PaymentStyle>
  )
}