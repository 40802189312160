import { Skeleton, Text } from '@mantine/core'
import axios from 'axios'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useQuery } from '@tanstack/react-query'
import GiveGrade from './GiveGrade'

const AnswerSection = ({ props }) => {

  const { type, showPart, studentToTest } = props

  let link = type === "course" ? `/answer/course/answers/?user_id=${studentToTest?.id}&section_id=${showPart?.section?.id}` : `/answer/internship/answers/?user_id=${studentToTest?.id}&section_id=${showPart?.section?.id}`

  const [ currentAnswer, setCurrentAnswer ] = useState(null)

  const { data:userAnswers } = useQuery(["userAnswers", showPart?.section?.id],
    async () => await axios.get(link).then(res => res.data),
    { enabled: !!showPart?.section?.id }
  )

  useEffect(() => {
    if(userAnswers){
      let answer = userAnswers?.find(answer => {
        return (answer?.section_text === showPart?.entry?.id || answer?.section_video === showPart?.entry?.id || answer?.section_chart === showPart?.entry?.id)
      })
      setCurrentAnswer(answer)
    }
    return () => {
      setCurrentAnswer(null)
    }
  }, [userAnswers]);

  if(currentAnswer === null){
    return(
      <>
        <br /><br />
        <Skeleton width="750px" height="50px" mb={20} />
        <Skeleton width="750px" height="100px" />
      </>
    )
  }

  if(currentAnswer !== undefined && currentAnswer !== null && (currentAnswer?.section_text === showPart?.entry?.id || currentAnswer?.section_video === showPart?.entry?.id || currentAnswer?.section_chart === showPart?.entry?.id)){
    return(
      <>
        <AnswerStyle>
          <div className="wrapperX">
            <div style={{display:"flex", alignItems:"center", margin:"0rem 0 2rem 0"}}>
              <h2 style={{margin:"0"}}>User answer</h2>
              <ins style={{fontWeight:"lighter", fontSize:"14px", margin:".25rem 0 0 .5rem", color:"#555", textDecoration:"none"}}>
                [{currentAnswer?.created_at.substring(0,10)} {"-"} {currentAnswer?.created_at.substring(11,16)}]
              </ins>
            </div>
            {
              currentAnswer?.answer_text &&
                parse(currentAnswer?.answer_text)
            }
            {
              currentAnswer?.answer_file &&
              <div>
                <div style={{display:"flex", alignItems:"center", marginBottom:"1rem"}}>
                  <p style={{margin:"0 0.5rem 0 0"}}>File: </p>
                  <a target="_blank" rel="noreferrer" href={currentAnswer?.answer_file}>{currentAnswer?.answer_file?.substring(100,200)}</a>
                </div>
                {/* <img style={{ width:"250px", height:"250px", objectFit:"contain" }} src={currentAnswer?.answer_file} alt="" /> */}
              </div>
            }
            {
              currentAnswer?.answer_link &&
              <div style={{display:"flex", alignItems:"center"}}>
                <p style={{margin:"0 0.5rem 0 0"}}>Link: </p>
                <a target="_blank" rel="noreferrer" href={currentAnswer?.answer_link}>{currentAnswer?.answer_link}</a>
              </div>
            }
          </div>
        </AnswerStyle>
        <GiveGrade props={{ userAnswer:currentAnswer, forUsers:false, type }}/>
      </>
    )
  }

  return (
    <div style={{ backgroundColor:"#f8f8f8", padding:"1rem", borderRadius:".5rem" }}>
      <Text color='yellow.8'>User has not answered to this question yet</Text>
    </div>
  )
}

export default AnswerSection

const AnswerStyle = styled.div`
  margin-top: 2rem;
  .wrapperX{
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2{
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider{
      margin-top: 1rem;
      p{
        margin-top: 0;
      }
    }
    button{
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      &:hover{
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width:1024px){
    .wrapperX{
      max-width: 100%;
      padding: 1.5rem;
    }
  }
`

const AnswerFormStyle = styled.div`
  margin-top: 2rem;
  form{
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2{
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider{
      margin-top: 1rem;
      p{
        margin-top: 0;
      }
    }
    .submitAnswer{
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      border-radius: 0.25rem;
      color: #000;
      font-weight: 500;
      &:hover{
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width:1024px){
    border-bottom: 1px solid #eee;
    form{
      background: none;
      padding: 1.5rem 0rem;
      min-width: 100%;
      margin: 0 auto;
      border-radius: 0.5rem;
      border: none;
      h2{
        margin-top: 0;
        margin-bottom: 2rem;
      }
      .slider{
        margin-top: 1rem;
        p{
          margin-top: 0;
        }
      }
      .submitAnswer{
        width: 100%;
        background-color: var(--yellow);
        padding: 0.5rem 3rem;
        margin-top: 1.5rem;
        border: 0;
        font-size: 15px;
        border-radius: 0.25rem;
        &:hover{
          background-color: var(--yellow-shadow);
        }
      }
    }
  }
`