import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import defaultImage from "../../Assets/Images/defaultImage.webp";
import { useQuery } from "@tanstack/react-query";

export function AboutAuthor({ props }) {
  const { entryData, type } = props;

  let userObject = {};

  if (type === "course") {
    const { teacher } = entryData;
    userObject = { ...teacher };
  }

  if (type === "internship") {
    const { user } = entryData;
    userObject = { ...user };
  }

  const {
    id: teacherId,
    first_name,
    last_name,
    bio,
    profile_img,
    occupation,
  } = userObject;

  let link =
    type === "course"
      ? `api/usercourselist/${teacherId}`
      : `internship/userinternshiplist/${teacherId}`;

  const { data: teacherPrograms } = useQuery(
    ["other-programs-by-teacher"],
    async () => {
      return await axios.get(link).then((res) => res.data);
    }
  );

  const otherPrograms = teacherPrograms?.filter(
    (program) =>
      program?.id !== parseInt(entryData?.id) && program.is_shared === true
  );

  const noProgram = otherPrograms?.length < 1 && (
    <div className="noProgram">
      <h3>No other programs by this user</h3>
    </div>
  );

  const programList = otherPrograms?.length > 0 && (
    <div className="programs">
      <h3>Other programs:</h3>
      {otherPrograms?.map((program, index) => {
        return (
          program.is_shared === true &&
          index < 3 && (
            <Link to={`/internship/${program?.id}/`} key={program?.id}>
              <div className="cardWrapper">
                <img src={program?.image} alt="" />
                <p>{program?.title?.substring(0, 54)}</p>
              </div>
            </Link>
          )
        );
      })}
      <Link to={`/consultant/${teacherId}`} className="seeAll">
        <h2>SEE ALL</h2>
      </Link>
    </div>
  );

  return (
    <AboutAuthorStyle>
      <h1 className="desktoph1">About the author</h1>
      <div className="wrapper">
        <h1 className="mobileh1">About the author</h1>
        <div className="author">
          <div className="imgAndName">
            <img
              src={
                profile_img?.includes("media/download.png")
                  ? defaultImage
                  : profile_img
              }
              alt=""
            />
            <div>
              <h3>{first_name + " " + last_name}</h3>
              <ins>{occupation}</ins>
              <Link to={`/consultant/${teacherId}`}>See profile</Link>
            </div>
          </div>
          <p>
            {bio
              ? bio?.length > 100
                ? bio?.substring(0, 99) + "..."
                : bio?.substring(0, 99)
              : ""}
          </p>
        </div>
        {programList}
        {noProgram}
      </div>
    </AboutAuthorStyle>
  );
}

const AboutAuthorStyle = styled.div`
  h1,
  h2,
  h3 {
    font-weight: 500;
  }
  h1 {
    text-align: center;
    font-size: 36px;
  }
  .mobileh1 {
    display: none;
  }
  .wrapper {
    display: flex;
    // grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: #f8f8f8;
    padding: 1.5rem 0rem 1.5rem 2rem;
    width: 80%;
    max-width: min-content;
    margin: 0 auto;
    margin-bottom: 5rem;
    border-radius: 0.5rem;
    .noProgram {
      h3 {
        min-width: 200px;
        text-align: center;
        color: #999;
        margin: 1rem 2rem;
      }
    }
    .author {
      max-width: min-content;
      p {
        margin-bottom: 0;
        max-width: 400px;
      }
      a {
        color: #44f;
        text-decoration: none;
      }
      .imgAndName {
        display: flex;
        align-items: center;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 0.5rem;
          object-fit: cover;
        }
        div {
          margin-left: 1rem;
          h3 {
            margin: 0;
            min-width: max-content;
          }
          ins {
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
    .programs {
      // max-width: min-content;
      width: 100%;
      display: flex;
      margin-left: -2rem;
      .other-programs {
        display: flex;
      }
      a {
        text-decoration: none;
        color: #000;
        margin: 0 0.5rem;
      }
      h2 {
        text-align: center;
        font-size: 24px;
      }
      h3 {
        display: flex;
        align-items: flex-end;
        transform: rotate(-90deg);
        margin-right: 0.8rem;
        color: #bbb;
        min-width: max-content;
      }
      .seeAll {
        display: flex;
        align-items: center;
        max-width: min-content;
        margin: 0 1.5rem 0 0.75rem;
        * {
          font-size: 18px;
        }
      }
      .cardWrapper {
        background-color: #fff;
        width: 200px;
        height: 100%;
        box-shadow: 0 0 10px 5px #00000011;
        border-radius: 0.5rem;
        overflow: hidden;
        transition: all 0.2s;
        img {
          width: 100%;
          height: 6rem;
          object-fit: cover;
        }
        p {
          margin: 0.5rem 0;
          padding: 0 1rem;
        }
        :hover {
          box-shadow: 0 0 10px 5px #00000022;
        }
      }
    }
  }
  @media screen and (max-width: 1350px) {
    .wrapper {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    .desktoph1 {
      display: none;
    }
    .wrapper {
      .mobileh1 {
        display: block;
        margin-top: 1rem;
        margin-bottom: 2.5rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: initial;
      border-radius: 0;
      margin: 0 auto;
      padding: 2rem 0;
      .noProgram {
        h3 {
          border: 1px solid #bbb;
          padding: 1rem;
          border-radius: 0.25rem;
          min-width: 200px;
          text-align: center;
          color: #999;
          margin: 2rem 0 1rem 0;
        }
      }
      .author {
        max-width: initial;
        max-width: 80%;
        margin: 0 auto;
        p {
          margin-bottom: 0;
          min-width: 250px;
          max-width: 300px;
        }
        a {
          color: #44f;
          text-decoration: none;
        }
        .imgAndName {
          display: flex;
          align-items: center;
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 0.5rem;
            object-fit: cover;
          }
          div {
            margin-left: 1rem;
            h3 {
              min-width: max-content;
            }
            ins {
              text-decoration: none;
              color: #000;
            }
          }
        }
      }
      .programs {
        max-width: min-content;
        display: block;
        margin-left: 0;
        margin-top: 0.5rem;
        a {
          text-decoration: none;
          color: #000;
          margin: 0 0.5rem;
        }
        h2 {
          text-align: center;
          font-size: 24px;
        }
        h3 {
          display: flex;
          align-items: flex-end;
          transform: rotate(0deg);
          margin: 0 auto;
          margin-top: 2rem;
          color: #bbb;
          min-width: max-content;
        }
        .seeAll {
          max-width: max-content;
          margin: 0 auto;
          * {
            text-align: center;
            font-size: 18px;
          }
        }
        .cardWrapper {
          background-color: #fff;
          min-width: 300px;
          height: 100%;
          box-shadow: 0 0 10px 5px #00000011;
          border-radius: 0.5rem;
          overflow: hidden;
          transition: all 0.2s;
          img {
            width: 100%;
            height: 8rem;
            object-fit: cover;
          }
          p {
            margin: 0.5rem 0;
            padding: 0 1rem 1rem 1rem;
            font-size: 18px;
          }
          :hover {
            box-shadow: 0 0 10px 5px #00000022;
          }
        }
      }
    }
  }
`;
