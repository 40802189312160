import { React } from "react";
import styled from "styled-components";
import { ChevronDown } from "tabler-icons-react";
import figures1 from "../../../Assets/SVGs/figures1.png";
import figures2 from "../../../Assets/SVGs/figures2.png";
import figures3 from "../../../Assets/SVGs/figures3.svg";

export const ForthPart = () => {
  return (
    <ForthPartStyle>
      <div className="arrowDiv1">
        <img className="arrows" src={figures3} alt="" />
      </div>
      <div className="arrowDiv2">
        <img className="arrows" src={figures3} alt="" />
      </div>
      <div className="wrapper">
        <div className="telim">
          <div className="img">
            <img src={figures1} alt="" />
          </div>
          <h2>Skill building</h2>
          <p>
            Choose a topic and start to build your future with lifelong
            learning!
          </p>
          <div>
            <div className="selectdiv">
              <label>
                <select name="occupation">
                  <option label="" value="">
                    Choose a topic
                  </option>
                  <option label="" defaultValue="UX Designer">
                    UX Designer
                  </option>
                </select>
                <span>
                  <ChevronDown />
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="test">
          <div className="img">
            <img src={figures2} alt="" />
          </div>
          <h2>Internships</h2>
          <p>
            Use your creativity, be bold, get yourself ready for a real business
            environment!
          </p>
          <div>
            <div className="selectdiv">
              <label>
                <select name="occupation">
                  <option label="" value="">
                    Choose a topic
                  </option>
                  <option label="" defaultValue="UX Designer">
                    UX Designer
                  </option>
                </select>
                <span>
                  <ChevronDown />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </ForthPartStyle>
  );
};

const ForthPartStyle = styled.div`
  margin-bottom: 12rem;
  width: 100%;
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
    align-items: center;
    text-align: center;
    div {
      input,
      select {
        font-family: "Euclid";
        padding: 0.8rem 1.5rem;
        margin: 0.7rem 0;
        border-radius: 0.4rem;
        border: 1px solid #aaa;
        font-size: 15px;
        color: #777;
        appearance: none;
      }
    }
    h2 {
      color: #4f4f4f;
      font-size: clamp(1.6rem, 5vw, 1.8rem);
    }
    p {
      color: #828282;
      font-size: clamp(1rem, 2.5vw, 1.1rem);
    }
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 15rem;
    }
    .telim,
    .test {
      .selectdiv {
        position: relative;
        display: flex;
        max-width: 300px;
        margin: 0 auto;
        z-index: 5;
        label {
          width: 100%;
          span {
            right: 1.6rem;
            top: 0.6rem;
            height: 34px;
            padding: 15px 0px 0px 8px;
            position: absolute;
            pointer-events: none;
          }
        }
        select {
          box-shadow: 0 0 10px 0 #00000033;
          border: 2px solid #000;
          background-color: #fff;
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          & ::-ms-expand {
            display: none;
          }
        }
      }
    }
  }
  .arrowDiv1 {
    display: flex;
    justify-content: center;
    width: 100%;
    .arrows {
      left: 7%;
      width: 10%;
      margin-top: -7rem;
      position: absolute;
      transform: rotate(-150deg);
    }
  }
  .arrowDiv2 {
    display: flex;
    justify-content: center;
    width: 100%;
    .arrows {
      right: 2%;
      transform: translateY(11rem);
      width: 20%;
      margin-top: 3rem;
      position: absolute;
    }
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0rem;
    width: 100%;
    background-color: #f8f8f8;
    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0rem;
      align-items: center;
      text-align: center;
      padding: 2rem 0 0 0;
      div {
        input,
        select {
          font-family: "Euclid";
          padding: 0.8rem 1.5rem;
          margin: 0.7rem 0;
          border-radius: 0.4rem;
          border: 1px solid #aaa;
          font-size: 15px;
          color: #777;
          appearance: none;
        }
      }
      h2 {
        color: #4f4f4f;
      }
      p {
        color: #828282;
      }
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 15rem;
      }
      .telim,
      .test {
        background-color: #fff;
        margin: 1rem;
        border-radius: 0.5rem;
        padding: 1rem;
        padding-top: 0;
        padding-bottom: 2rem;
        div {
          width: 95%;
          margin: 0 auto;
        }
        .selectdiv {
          position: relative;
          display: flex;
          max-width: 300px;
          margin: 0 auto;
          label {
            width: 100%;
            span {
              right: 1.6rem;
              top: 0.6rem;
              height: 34px;
              padding: 15px 0px 0px 8px;
              position: absolute;
              pointer-events: none;
            }
          }
          select {
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            & ::-ms-expand {
              display: none;
            }
          }
        }
      }
    }
    .arrowDiv1 {
      display: none;
    }
    .arrowDiv2 {
      display: none;
    }
  }
`;
